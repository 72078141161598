/* eslint-disable no-undef */
/* eslint-disable no-loop-func */
/* eslint-disable no-unused-expressions */
import moment, { isMoment } from 'moment';
import React, { Suspense, lazy } from 'react';
import axios from 'axios';
import {
  has,
  get,
  some,
  cloneDeep,
  upperFirst,
  isArray,
  uniqBy,
  isString,
  size,
  camelCase,
  isObject,
  snakeCase,
} from 'lodash';
import { Button, Grid } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import RecallIcon from '@material-ui/icons/SwapHoriz';
import parsePhoneNumber from 'libphonenumber-js';
import { getOrientation } from 'get-orientation';
import SplitReactSDK from 'split.sdk';
import i18n from '../i18n';
import localStore from './localStorage';
import {
  SKOOLNET_ERRORS,
  errorCss,
  primaryColor,
  secondaryColor,
  HR_24_SHORT_FORMAT,
  SAVE_DATE_FORMAT,
  YEAR_MONTH_FORMAT,
  HR_24_FORMAT,
  HR_24_AM_PM,
  ENROLLED,
  ADMISSION_STATUS,
  TO_BE_ENROLLED,
  ENQUIRED,
  GRADUATED,
  WITHDRAWN,
  TEMP_WITHDRAWN,
  DATE_FORMAT,
  DATE_FORMAT_SHORT,
  CHILD_DETAIL,
  MIME_FILES,
  DATE_TIME_FORMAT,
  PROGRAM_TYPES,
  DEFAULT_ERROR,
  REJECTED,
  PUBLISHED,
  DRAFT,
  PENDING,
  APPROVE,
  FULL_MONTH_FORMAT,
  POST,
  ALBUM,
  FOLIETTE,
  OBSERVATION,
  CHILD_AT_WORK,
  CHILD_WORK_SAMPLE,
  MFS_ID,
  LSH_ID,
  PSS,
  MEDIA_DATA,
  CLASS_ACTIVITY_IMAGE_MAX_WIDTH_PORTRAIT_TEXT,
  CLASS_ACTIVITY_IMAGE_MAX_HEIGHT_PORTRAIT_TEXT,
  CLASS_ACTIVITY_IMAGE_MAX_WIDTH_LANDSCAPE_TEXT,
  CLASS_ACTIVITY_IMAGE_MAX_HEIGHT_LANDSCAPE_TEXT,
  CLASS_ACTIVITY_IMAGE_COMPRESSION_PERCENT_TEXT,
  CLASS_ACTIVITY_IMAGE_MAX_WIDTH_PORTRAIT,
  CLASS_ACTIVITY_IMAGE_MAX_HEIGHT_PORTRAIT,
  CLASS_ACTIVITY_IMAGE_MAX_WIDTH_LANDSCAPE,
  CLASS_ACTIVITY_IMAGE_MAX_HEIGHT_LANDSCAPE,
  CLASS_ACTIVITY_IMAGE_COMPRESSION_PERCENT,
} from './constants';
import { BASE_COLOR } from '../theme/variables';

const Image = lazy(() => import('../components/common/Image'));

export const extractMimeTypeFromFileName = filename => {
  const extension = filename.substring(filename.lastIndexOf('.') + 1);
  if (extension === 'xls') {
    return 'application/vnd.ms-excel';
  }
  if (extension === 'xlsx') {
    return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  }
  return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
};

export const isValidUrl = link => {
  if (!link) {
    return null;
  }
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$',
    'i'
  );
  return pattern.test(link);
};
export const getPrefectUrl = url => {
  const expression =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  const regex = new RegExp(expression);
  if (regex.test(url)) {
    let currentUrl = url;
    if (!url.toString().includes('http') || !url.toString().includes('https')) {
      currentUrl = `https://${url}`;
    }
    return currentUrl;
  }
  if (url.includes('www')) {
    return `https://${url}`;
  }
  return `https://www.${url}`;
};

export const downloadImage = (files, data) => {
  const isVideo = get(data, 'isVideo');
  axios({
    url: files,
    method: 'GET',
    responseType: 'blob',
    headers: {
      'Access-Control-Allow-Origin': '*',
      crossorigin: true,
      'Cache-Control': 'no-cache',
    },
  })
    .then(resp => {
      const fileType = isVideo ? get(isVideo, 'type').split('/')[1] : 'jpg';
      const url = URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${isVideo ? 'Video' : 'Image'}-${moment().format(
          'YYYYMMDD-x'
        )}.${fileType}`
      );
      document.body.appendChild(link);
      link.click();
    })
    .catch(() => {
      window.open(files, '_blank');
      return null;
    });
};

export const getCapitalisePureString = string => {
  if (string) {
    if (string.includes('_')) {
      const str = string.replace('_', ' ');
      return str.toUpperCase();
    }
    return string.toUpperCase();
  }
  return string;
};

/**
 * @desc Used to generate the filter parts of queries where necessary
 * without having to go deal with complicated brackets
 *
 * @param {string/object} $type - type of modifier for filter
 * @param string $value: required if datatype of $type is string - value associated with the $type
 *
 * for object $type: {
 *  eq: value_1,
 *  neq: value_2
 * }
 */
export const getRequestFilterModifier = (type, value = null) => {
  const allowedModifiers = [
    'between',
    'eq',
    'neq',
    'gt',
    'lt',
    'lte',
    'gte',
    'like',
  ];
  if (typeof type === 'string') {
    if (allowedModifiers.indexOf(type) === -1) {
      throw new Error('Unkonwn filter type: ', type);
    }

    let retVal = value;
    if (type !== 'like' && type !== 'between') {
      retVal = [value];
    }

    return [
      {
        [type]: retVal,
      },
    ];
  }

  if (typeof type === 'object') {
    const typeKeys = Object.keys(type);
    const retData = [];

    typeKeys.forEach(eachType => {
      if (allowedModifiers.indexOf(eachType) === -1) {
        throw new Error('Unkonwn filter type: ', eachType);
      }

      let eachVal = type[eachType];
      if (eachType !== 'like' && eachType !== 'between') {
        eachVal = [type[eachType]];
      }

      retData.push({
        [eachType]: eachVal,
      });
    });

    return retData;
  }

  return null;
};

export const isPrOrCitizen = nationality =>
  ['pr', 'citizen'].includes(nationality);

export const isValidNric = nric => {
  if (typeof nric !== 'string') {
    return false;
  }

  const regEx = new RegExp('^[A-Z][0-9]{7}[A-Z]$');
  return regEx.test(nric);
};

export const isValidChildBC = nric => {
  if (typeof nric !== 'string') {
    return false;
  }
  const regEx9Digit = new RegExp('^[A-Z][0-9]{7}[A-Z]$');
  const regExLast4Digit = new RegExp('^[0-9]{3}[A-Z]$');
  return regEx9Digit.test(nric) || regExLast4Digit.test(nric);
};

export const isValidLast4ChildBC = nric => {
  if (typeof nric !== 'string') {
    return false;
  }
  const regExLast4Digit = new RegExp('^[0-9]{3}[A-Z]$');
  return regExLast4Digit.test(nric);
};

export const getMaskedLast4ChildBC = nric => {
  if (typeof nric !== 'string') {
    return false;
  }
  const last4 = nric.slice(-4);
  return `****${last4}`;
};

export const capitaliseFirstLetter = string => {
  if (string) {
    let str = string;
    if (string.includes('_')) {
      str = string.replace('_', ' ');
    }
    const stringSplit = str.split(' ');
    for (let i = 0; i < stringSplit.length; i++) {
      stringSplit[i] =
        stringSplit[i].charAt(0).toUpperCase() + stringSplit[i].slice(1);
    }
    const joinString = stringSplit.join(' ');
    return joinString;
  }
  return string;
};

export const getDocumentWidth = () => {
  if (document) {
    return get(document, 'body.scrollWidth', 1024);
  }

  return 1024;
};

export const ImageLazyLoader = props => (
  <Suspense fallback={() => <div />}>
    <Image {...props} />
  </Suspense>
);

export const scrollToTop = () => {
  if (document) {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
};

export const scrollToBottom = selectedContent => {
  if (document) {
    if (!selectedContent) {
      window.scrollTo(0, document.body.scrollHeight);
    } else {
      window.scrollTo(
        0,
        document.querySelector(`.${selectedContent}`).scrollHeight
      );
    }
  }
};

export const getSplitName = fullName => {
  const nameParts = fullName.split(' ');
  const firstName = nameParts[0];
  const lName = [];

  nameParts.forEach((eachPart, idx) => {
    if (idx !== 0 && eachPart !== ' ' && eachPart) {
      lName.push(eachPart.trim());
    }
  });

  let lastName = '';

  if (lName.length === 1) {
    lastName = lName[0];
  } else if (lName.length) {
    lastName = lName.join(' ');
  }

  return {
    firstName: firstName.trim(),
    lastName,
  };
};

export const getPrimaryColor = (props, setting = 'main') => {
  let priColor = primaryColor;

  if (props.theme && props.theme.palette && props.theme.palette.primary) {
    priColor = props.theme.palette.primary[setting];
  }

  return priColor;
};

export const getSecondaryColor = (props, setting = 'light') => {
  let secColor = secondaryColor;
  if (props.theme && props.theme.palette && props.theme.palette.secondary) {
    secColor = props.theme.palette.secondary[setting];
  }
  return secColor.replace(')', ', 0.4)').replace('rgb', 'rgba');
};

export function getGraphQLError(error) {
  if (get(error, 'graphQLErrors')) {
    return get(error, 'graphQLErrors[0].message', '').replace(
      /GraphQL error./,
      ''
    );
  }
  return get(error, 'error[0].message', '').replace(/GraphQL error:/, '');
}

export const parseGraphQLError = error => {
  const newErr = cloneDeep(error);

  const trimGraphqlError = errMsg => errMsg.replace(/GraphQL error./, '');

  if (get(error, 'graphQLErrors[0].message')) {
    newErr.graphQLErrors[0].message = trimGraphqlError(
      error.graphQLErrors[0].message
    );
  }

  if (get(error, '[0].message')) {
    newErr[0].message = trimGraphqlError(error[0].message);
  }

  if (get(error, 'message')) {
    newErr.message = trimGraphqlError(error.message);
  }
  return newErr;
};

export const textNewline = (
  str,
  limit = 0,
  singleWord = false,
  forMarkdown = false
) => {
  if (!limit) {
    const screenWidth = getDocumentWidth();
    limit = 25;
    if (screenWidth > 1300) {
      limit = 35;
    }

    if (screenWidth > 1500) {
      limit = 50;
    }
  }

  let retStrArr = [str];
  try {
    if (!str) {
      throw new Error('No string value passed');
    }

    if (typeof str !== 'string') {
      throw new Error('Can only perform ellipsis on string value');
    }

    if (limit && typeof limit !== 'number') {
      throw new Error('Limit needs to be a number');
    }

    if (singleWord) {
      return str;
    }

    const txtArr = str.split(' ');

    retStrArr = [];
    let totalLength = 0;

    let strItem = '';
    txtArr.forEach(word => {
      totalLength += word.length + 1;
      if (totalLength > limit) {
        retStrArr.push(strItem);
        totalLength = 0;
        strItem = `${word} `;
      } else {
        strItem += `${word} `;
      }
    });

    if (strItem) {
      retStrArr.push(strItem);
    }
  } catch (ex) {
    console.warn(ex);
  }

  if (forMarkdown) {
    return retStrArr.join('\n');
  }

  return retStrArr.map(eachLine => (
    <>
      <span>{eachLine}</span>
      <br />
    </>
  ));
};

export const textEllipsis = (str, limit = 45, singleWord = false) => {
  let retStr = str;
  try {
    if (!str) {
      throw new Error('No string value passed');
    }

    if (typeof str !== 'string') {
      throw new Error('Can only perform ellipsis on string value');
    }

    if (limit && typeof limit !== 'number') {
      throw new Error('Limit needs to be a number');
    }

    if (singleWord) {
      if (str.length <= limit) {
        return str;
      }
      return `${str.slice(0, limit)}...`;
    }

    const txtArr = str.split(' ');

    retStr = '';
    let totalLength = 0;

    txtArr.forEach(word => {
      totalLength += word.length + 1;
      if (totalLength > limit) {
        if (retStr.indexOf('...') === -1) {
          retStr = `${retStr.slice(0, retStr.length - 3)}...`;
        }
      } else {
        retStr += `${word} `;
      }
    });
  } catch (ex) {
    console.warn(ex);
  }

  return retStr;
};

export const handleResponse = resp => {
  const retResp = {
    success: false,
    data: null,
    error: ['Something went wrong'],
  };

  if (resp.data && resp.data.errors) {
    retResp.error = resp.data.errors;
  } else if (resp.data && resp.data.data && resp.data.data.error) {
    retResp.error = resp.data.data.error;
  } else {
    retResp.success = true;
    retResp.data = resp.data.data;
    retResp.error = null;
  }

  return retResp;
};

export const tokenString = resp => {
  if (!resp) {
    return null;
  }

  const paramList = resp.split('?')[1].split('&');
  const myObj = {};
  paramList.map(obj => {
    const splitObj = obj.split('=');
    myObj[splitObj[0]] = splitObj[1];
  });
  return myObj;
};

export const tokenData = token => {
  if (token) {
    const splitStr = token.split('.');

    if (splitStr.length && splitStr[1]) {
      const decodeStr = splitStr[1];
      try {
        return JSON.parse(atob(decodeStr));
      } catch (ex) {
        return null;
      }
    }
  }

  return null;
};

export const updateDataForObj = (srcObj, tarObj) => {
  if (
    typeof srcObj !== 'object' ||
    typeof srcObj.length === 'number' ||
    typeof tarObj !== 'object' ||
    typeof tarObj.length === 'number'
  ) {
    throw new Error('Needs a valid JSON');
  }

  Object.keys(tarObj).forEach(eachKey => {
    if (typeof tarObj[eachKey] !== 'undefined') {
      if (
        typeof tarObj[eachKey] === 'object' &&
        typeof tarObj[eachKey] !== 'number'
      ) {
        tarObj[eachKey] = updateDataForObj(srcObj[eachKey], tarObj[eachKey]);
      } else {
        tarObj[eachKey] = srcObj[eachKey];
      }
    }
  });

  return tarObj;
};

export const localSaveState = (key, stateData) => {
  if (!this.saveState) {
    this.saveState = (data, cb) => {
      this.setState(data, () => {
        localStore.setValue(key, this.state);
        cb && cb();
      });
    };
  }

  try {
    const savedStateData = localStore.getValue(key);

    if (savedStateData) {
      stateData = updateDataForObj(savedStateData, stateData);
    }
  } catch (ex) {
    // handle exception case
  }

  this.state = stateData;
};

export const emailValidator = email => {
  // Email validation
  let valid = true;
  const errors = {};

  if (!email) {
    valid = false;
    errors.email = '*Required';
  } else if (typeof email !== 'undefined') {
    const lastAtPos = email.lastIndexOf('@');
    const lastDotPos = email.lastIndexOf('.');

    if (
      !(
        lastAtPos < lastDotPos &&
        lastAtPos > 0 &&
        email.indexOf('@@') === -1 &&
        lastDotPos > 2 &&
        email.length - lastDotPos > 2
      )
    ) {
      valid = false;
      errors.email = "Email is not valid, include '@' and '.'";
    }
  }
  return { valid, errors };
};

export const padNumber = (input, length = 2) => {
  while (`${input}`.length < length) {
    input = `0${input}`;
  }
  return input;
};

export const getMonths = monthConfig => {
  const months = [];

  for (let count = 1; count <= parseInt(monthConfig, 10); count++) {
    const month = moment().add(count, 'M');
    months.push({
      value: month.format(YEAR_MONTH_FORMAT),
      label: month.format('MMMM YYYY'),
    });
  }

  return months;
};

export const getDays = (month = 'January', startFrom = 1) => {
  const days = [];
  let numberOfDays = 31;
  if (month !== 'All') {
    numberOfDays = moment(month, 'MMMM').daysInMonth();
  }
  for (let idx = startFrom; idx <= numberOfDays; idx++) {
    const day = {
      label: '',
      value: idx,
    };
    if (idx % 10 === 1 && (idx < 10 || idx > 20)) {
      day.label = `${idx}st`;
    } else if (idx % 10 === 2 && (idx < 10 || idx > 20)) {
      day.label = `${idx}nd`;
    } else if (idx % 10 === 3 && (idx < 10 || idx > 20)) {
      day.label = `${idx}rd`;
    } else {
      day.label = `${idx}th`;
    }
    days.push(day);
  }
  return days;
};

export const processCalendarEvents = (data, type, existingEvents = []) => {
  const events = [];
  switch (type) {
    case 'visits': {
      data
        .filter(event => event.available)
        .forEach(event => {
          if (event.recurType) {
            const diffinDays = Math.abs(
              moment(event.start).diff(moment(event.end), 'days')
            );
            const diffinWeeks = Math.ceil(
              Math.abs(
                moment(event.start).diff(moment(event.end), 'weeks', true)
              )
            );
            const diffinMonths = Math.ceil(
              Math.abs(
                moment(event.start).diff(moment(event.end), 'months', true)
              )
            );

            const startDate = moment(event.start);
            const endDate = moment(event.end);

            switch (event.recurType) {
              case 'daily': {
                for (let idx = 0; idx <= diffinDays; idx++) {
                  const recurringEvent = Object.assign({}, event);
                  recurringEvent.actualStart = event.start;
                  recurringEvent.actualEnd = event.end;
                  recurringEvent.start = new Date(
                    `${moment(event.start)
                      .add(idx, 'days')
                      .format('YYYY-MM-DD')} ${event.from}`
                  );
                  recurringEvent.end = new Date(
                    `${moment(event.start)
                      .add(idx, 'days')
                      .format('YYYY-MM-DD')} ${event.to}`
                  );

                  if (
                    !some(existingEvents, {
                      start: recurringEvent.start,
                      end: recurringEvent.end,
                      ID: recurringEvent.ID,
                    })
                  ) {
                    events.push(recurringEvent);
                  }
                }
                break;
              }
              case 'weekly': {
                let startIdx = 0;
                const recurringDays = event.recurOnDays
                  .split(',')
                  .sort((a, b) => a - b);
                const eventStartDate = moment(event.start)
                  .startOf('week')
                  .add(parseInt(recurringDays[0], 10), 'days');
                if (startDate.isAfter(eventStartDate, 'days')) {
                  startIdx = 1;
                }

                for (let idx = startIdx; idx <= diffinWeeks; idx++) {
                  recurringDays.forEach(day => {
                    const recurringEvent = Object.assign({}, event);
                    const date = moment(event.start)
                      .add(idx, 'weeks')
                      .startOf('week')
                      .add(day, 'days');

                    if (date.isSameOrBefore(endDate, 'days')) {
                      recurringEvent.actualStart = event.start;
                      recurringEvent.actualEnd = event.end;
                      recurringEvent.start = new Date(
                        `${date.format('YYYY-MM-DD')} ${event.from}`
                      );
                      recurringEvent.end = new Date(
                        `${date.format('YYYY-MM-DD')} ${event.to}`
                      );
                      if (
                        !some(existingEvents, {
                          start: recurringEvent.start,
                          end: recurringEvent.end,
                          ID: recurringEvent.ID,
                        })
                      ) {
                        events.push(recurringEvent);
                      }
                    }
                  });
                }
                break;
              }
              case 'monthly': {
                let startIdx = 0;
                const eventStartDate = moment(event.start).set(
                  'date',
                  parseInt(event.recurOnDays, 10)
                );
                if (startDate.isAfter(eventStartDate, 'days')) {
                  startIdx = 1;
                }
                for (let idx = startIdx; idx <= diffinMonths; idx++) {
                  const recurringEvent = Object.assign({}, event);
                  const date = moment(event.start)
                    .add(idx, 'months')
                    .set('date', parseInt(event.recurOnDays, 10));

                  if (date.isSameOrBefore(endDate, 'days')) {
                    recurringEvent.actualStart = event.start;
                    recurringEvent.actualEnd = event.end;
                    recurringEvent.start = new Date(
                      `${date.format('YYYY-MM-DD')} ${event.from}`
                    );
                    recurringEvent.end = new Date(
                      `${date.format('YYYY-MM-DD')} ${event.to}`
                    );
                    if (
                      !some(existingEvents, {
                        start: recurringEvent.start,
                        end: recurringEvent.end,
                        ID: recurringEvent.ID,
                      })
                    ) {
                      events.push(recurringEvent);
                    }
                  }
                }
                break;
              }
              default:
                break;
            }
          } else {
            const mainEvent = Object.assign({}, event);
            mainEvent.start = new Date(
              `${moment(event.start).format('YYYY-MM-DD')} ${event.from}`
            );
            mainEvent.end = new Date(
              `${moment(event.start).format('YYYY-MM-DD')} ${event.to}`
            );
            mainEvent.user = null;
            if (
              !some(existingEvents, {
                start: mainEvent.start,
                end: mainEvent.end,
                ID: mainEvent.ID,
              })
            ) {
              events.push(mainEvent);
            }
          }

          if (event.visits.data.length) {
            event.visits.data.forEach(visit => {
              const visitEvent = Object.assign({}, event);
              visitEvent.start = new Date(
                moment(visit.visitFrom).format('YYYY-MM-DD HH:mm:ss')
              );
              visitEvent.end = new Date(
                moment(visit.visitTo).format('YYYY-MM-DD HH:mm:ss')
              );
              visitEvent.user = visit.user;
              visitEvent.visitID = visit.ID;
              visitEvent.isCompleted = visit.status === 'completed';
              visitEvent.enquiryId = visit.enquiry && visit.enquiry.ID;
              visitEvent.childId = visit.enquiry && visit.enquiry.fkChild;
              if (
                !some(existingEvents, {
                  start: visitEvent.start,
                  end: visitEvent.end,
                  ID: visitEvent.ID,
                })
              ) {
                events.push(visitEvent);
              }
            });
          }
        });

      break;
    }
    case 'holidays': {
      if (get(data, 'length')) {
        data.forEach(item => {
          const holiday = Object.assign({}, item);
          holiday.start = new Date(
            `${moment(item.from).format('YYYY-MM-DD HH:MM:SS')}`
          );
          holiday.end = new Date(
            `${moment(item.to).format('YYYY-MM-DD HH:MM:SS')}`
          );
          const hourDiff = moment(item.to).diff(moment(item.from), 'hours');
          holiday.isHoliday = true;
          if (!get(item, 'isPublic') && hourDiff < 23) {
            holiday.allDay = false;
          } else {
            holiday.allDay = true;
          }
          events.push(holiday);
        });
      }
      break;
    }
    case 'leaves': {
      if (get(data, 'length')) {
        data.forEach(item => {
          const leaves = Object.assign({}, item);
          leaves.start = new Date(
            `${moment(get(item, 'start')).format('YYYY-MM-DD HH:MM:SS')}`
          );
          leaves.end = new Date(
            `${moment(get(item, 'end')).format('YYYY-MM-DD HH:MM:SS')}`
          );
          leaves.allDay = true;
          leaves.isLeave = true;
          if (
            !some(
              events,
              eventItem =>
                eventItem.isLeave &&
                eventItem.start.getTime() === leaves.start.getTime() &&
                eventItem.end.getTime() === leaves.end.getTime() &&
                eventItem.leave.ID === leaves.leave.ID
            )
          ) {
            events.push(leaves);
          }
        });
      }
      break;
    }
    case 'events': {
      if (get(data, 'length')) {
        data.forEach(item => {
          const event = Object.assign({}, item);
          event.start = new Date(
            `${moment(item.from).format('YYYY-MM-DD HH:MM:SS')}`
          );
          event.end = new Date(
            `${moment(item.to).format('YYYY-MM-DD HH:MM:SS')}`
          );
          event.isEvent = true;
          events.push(event);
        });
      }
      break;
    }
    default:
      break;
  }
  return events;
};

export const mobileGoogleValidation = (number, countryCode) => {
  let valid = true;
  const errors = {};
  const MIN_LENGTH_FOR_NUMBER = 2;

  if (!number) {
    valid = false;
    errors.mobile = '*Required';
    return { valid, errors };
  }

  if (number.length < MIN_LENGTH_FOR_NUMBER) {
    valid = false;
    errors.mobile = 'Mobile Number not valid';
    return { valid, errors };
  }

  const isNumberNoDecimal = /^[0-9]*$/.test(number);
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(number) || !isNumberNoDecimal) {
    valid = false;
    errors.mobile = 'Mobile Number not valid';
    return { valid, errors };
  }

  try {
    if (number && countryCode) {
      const phoneNumber = parsePhoneNumber(number, countryCode);
      if (!phoneNumber.isValid()) {
        valid = false;
        errors.mobile = 'Mobile Number not valid';
      }
    }
  } catch (ex) {
    valid = false;
    errors.mobile = `Error while validating mobile number: ${ex.message}`;
  }
  return { valid, errors };
};

// TODO: Add in more check for more variants.
export const FormatAddress = (
  blockNo,
  floorNo,
  unitNo,
  streetName,
  postalCode
) =>
  `Blk ${blockNo} ${streetName} ${floorNo} - ${unitNo} Singapore ${postalCode}`;

export const getStatus = status => {
  let retVal = status;

  if (status.toLowerCase() === 'pending') {
    retVal = <span className="s7t-text-orange s7t-bold">Pending Approval</span>;
  } else if (status.toLowerCase() === 'approved') {
    retVal = <span className="s7t-text-green s7t-bold">Approved</span>;
  } else {
    retVal = <span className="s7t-text-watermelon s7t-bold">Declined</span>;
  }

  return retVal;
};

export const getActionsForApprover = ({
  centre,
  disabled,
  approverList,
  reviewClicked,
  viewClicked,
  hidebtn,
  recallClicked,
  blackList,
}) => {
  let isApprover = false;

  if (approverList && approverList.length) {
    approverList.forEach(eachCtr => {
      if (eachCtr.ID === centre) {
        isApprover = true;
      }
    });
  }

  if (isApprover && !blackList) {
    return (
      <Grid container spacing={1} wrap="nowrap">
        <Grid item md={12} lg={6} className="s7t-no-padding">
          <Button
            disabled={disabled}
            variant="text"
            color="primary"
            onClick={reviewClicked}
          >
            <VisibilityIcon
              className="w3-margin-right"
              style={{ fontSize: '15px' }}
            />
            Review
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={1} wrap="nowrap">
      <Grid item md={12} lg={6} className="s7t-no-padding">
        <Button variant="text" color="primary" onClick={viewClicked}>
          <VisibilityIcon style={{ fontSize: '15px', marginRight: '5px' }} />
          View
        </Button>
      </Grid>
      {!hidebtn && (
        <Grid item md={12} lg={6} className="s7t-no-padding">
          <Button variant="text" color="primary" onClick={recallClicked}>
            <RecallIcon style={{ fontSize: '15px', marginRight: '5px' }} />
            Recall
          </Button>
        </Grid>
      )}
      {isApprover && (
        <Grid item md={12} lg={6} className="s7t-no-padding">
          <Button
            disabled={disabled}
            variant="text"
            color="primary"
            onClick={reviewClicked}
          >
            <VisibilityIcon
              className="w3-margin-right"
              style={{ fontSize: '15px' }}
            />
            Review
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export const getFlexibleTime = (createdOn, isShortNotation = false) => {
  const today = moment();
  const daysDiff = today.diff(createdOn, 'days');
  const hourDiff = today.diff(createdOn, 'hours');
  let dateFormat = DATE_FORMAT;

  if (isShortNotation) {
    dateFormat = DATE_FORMAT_SHORT;
  }

  if (daysDiff > 3) {
    return moment(createdOn).format(dateFormat);
  }

  if (isShortNotation && hourDiff < 1) {
    return i18n.t('common.now');
  }

  if (isShortNotation) {
    let retTime = moment(createdOn).toNow(true);

    retTime = retTime.toLowerCase().replace(' hours', 'h');
    retTime = retTime.toLowerCase().replace(' hour', 'h');
    retTime = retTime.toLowerCase().replace(' minutes', 'm');
    retTime = retTime.toLowerCase().replace(' minute', 'm');
    retTime = retTime.toLowerCase().replace(' days', 'd');
    retTime = retTime.toLowerCase().replace('anh', 'an hr');
    retTime = retTime.toLowerCase().replace('ad', 'a day');
    retTime = retTime.toLowerCase().replace('am', 'a min');

    return retTime;
  }

  return `${moment(createdOn).toNow(true)} ${i18n.t('common.ago')}`;
};

export const s3FileUploadHandler = async (
  reqData,
  s3Url,
  s3Key,
  fileRef,
  successCb = () => {},
  errorCb = () => {},
  getMimeTypeExternally,
  extraData = null
) => {
  const data = new FormData();
  data.append('file', fileRef);
  data.append('key', s3Key);
  const fileRefData = fileRef;

  let fileType = fileRefData.type;
  if (!fileType && getMimeTypeExternally) {
    fileType = extractMimeTypeFromFileName(fileRef.name);
  }
  const options = {
    headers: {
      'Content-Type': fileType,
    },
  };
  return axios
    .put(s3Url, fileRefData, options)
    .then(resp => {
      if (resp && resp.status === 200 && resp && resp.statusText === 'OK') {
        return successCb(reqData);
      }
      return errorCb(reqData, '', 'extraData');
    })
    .catch(e => {
      console.error(`%c ${SKOOLNET_ERRORS} Error Uploading file`, errorCss, e);
      return errorCb(reqData, e, extraData);
    });
};

export const getQueryParams = () => {
  const retData = {};

  if (!window || !window.location || !window.location.search) {
    return retData;
  }

  const searchStr = window.location.search.split('?')[1];
  const getQueryParamObj = eachQuery => {
    const qDataArr = eachQuery.split('=');
    retData[qDataArr[0]] = qDataArr[1];
  };

  if (searchStr.includes('&')) {
    const queryStr = searchStr.split('&');
    queryStr.forEach(eachQuery => getQueryParamObj(eachQuery));
  } else {
    getQueryParamObj(searchStr);
  }

  return retData;
};

export const isUnauthorized = data =>
  get(data, 'error[0].message') === 'unauthorized';

export const trimInvalidDateTime = data => {
  if (typeof data !== 'object') {
    return data;
  }

  Object.keys(data).map(item => {
    if (
      (item.indexOf('date') !== -1 || item.indexOf('Date') !== -1) &&
      !data[item]
    ) {
      delete data[item];
    }

    if (
      data[item] !== null &&
      typeof data[item] === 'object' &&
      Object.keys(data[item]).length
    ) {
      trimInvalidDateTime(data[item]);
    }
  });

  return data;
};

export const getAccessByRole = (moduleCode, userRoles) => {
  let access = 0;

  if (!get(userRoles, 'length')) {
    return access;
  }

  userRoles.forEach(eachRole => {
    if (!get(eachRole, 'role.roleTemplates.data.length')) {
      return;
    }

    eachRole.role.roleTemplates.data.forEach(eachModuleCategory => {
      const accessVal = get(eachModuleCategory, 'access') || 0;

      if (
        get(eachModuleCategory, 'fkModuleCategory') === moduleCode &&
        accessVal > access
      ) {
        access = accessVal;
      }
    });
  });

  return access;
};

export const processFloat = value => {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(parseFloat(value))) {
    return null;
  }

  return parseFloat(value);
};

export const getUrl = () => {
  let url = '';
  if (window && window.location) {
    url = window.location.href;
  }

  return url;
};

export const getUrlPath = () => {
  let urlPath = '';
  if (window && window.location) {
    urlPath = window.location.pathname;
  }

  return urlPath;
};

export const getUrlPathAsArray = () => {
  let pathArr = [];
  if (window && window.location) {
    pathArr = window.location.pathname.split('/');
  }

  return pathArr;
};

export const replaceUrlParam = (url = '', params = {}) => {
  const urlParts = url.split('/');
  let retUrl = '';

  urlParts.map(eachPart => {
    const isUrlParam = eachPart.indexOf(':') > -1;
    const paramPart = params[eachPart.replace(':', '').replace('?', '')];
    if (isUrlParam && paramPart) {
      retUrl += `/${paramPart}`;
    } else if (!isUrlParam && eachPart) {
      retUrl += `/${eachPart}`;
    }
  });

  return retUrl;
};

export const getUrlWithQueryParams = (url, params = {}) => {
  let newUrl = '';
  Object.keys(params).forEach((name, index) => {
    if (index !== 0) {
      newUrl += '&';
    }
    newUrl += `${name}=${params[name]}`;
  });
  return `${url}?${newUrl}`;
};

/* eslint-disable no-bitwise */
export const bitwiseAndCheck = (givenVal, hasVal) => givenVal & hasVal;

export const parseObjFields = (obj, fields) => {
  if (typeof obj !== 'object') {
    return;
  }

  const newObj = Object.assign({}, obj);
  Object.keys(newObj).forEach(eachField => {
    const fieldType = fields[eachField];
    const { [eachField]: value } = newObj;
    if (value) {
      const decodedValue = decodeURIComponent(value);
      newObj[eachField] = decodedValue;
      switch (fieldType) {
        case 'int':
          newObj[eachField] = parseInt(decodedValue, 10);
          break;
        case 'float':
          newObj[eachField] = parseFloat(decodedValue, 10);
          break;
        case 'num_array':
          if (decodedValue.includes(',')) {
            newObj[eachField] = decodedValue.split(',').map(Number);
          } else {
            newObj[eachField] = [Number(decodedValue)];
          }
          break;
        case 'string_array':
          newObj[eachField] = decodedValue.split(',').map(String);
          break;
        case 'boolean':
          newObj[eachField] = decodedValue === 'true';
          break;
        case 'uri':
          newObj[eachField] = decodeURI(value);
          break;
        default:
          break;
      }
    }
  });

  return newObj;
};

export const checkAccess = (accessType, accessVal) => {
  let hasAccess = true;
  if (!(accessType & accessVal)) {
    hasAccess = false;
  }

  return hasAccess;
};

export const getSortObj = activeSort => {
  if (!activeSort) {
    return {
      sortVal: '',
      sortOrder: '',
    };
  }

  const activeParts = activeSort.split('--');
  const sortVal = activeParts[0];
  const sortOrder = activeParts[1];

  return {
    sortVal,
    sortOrder,
  };
};

export const isValid24HRFormat = time =>
  moment(time, HR_24_SHORT_FORMAT, true).isValid();

export const parse24HRTime = time => {
  if (!time) {
    return null;
  }
  if (!isValid24HRFormat(time)) {
    return moment(time, SAVE_DATE_FORMAT).format(HR_24_SHORT_FORMAT);
  }
  return time;
};

export const isMomentValid = date => {
  if (!date || !isMoment(date)) {
    return false;
  }

  return moment(date).isValid();
};

export const validateManuallyEnteredDate = date => {
  if (get(date, '_i', false) && date._i.indexOf('_') === -1) {
    const formattedDate = moment(date.format(DATE_FORMAT));
    return moment(formattedDate._i, DATE_FORMAT).isValid();
  }

  // Do not want to show error if date was deleted
  return true;
};

export const isFutureDate = value => moment().isBefore(moment(value), 'day');

export const fetchCategoryData = categoryData => {
  if (!categoryData) {
    return null;
  }

  if (has(categoryData, 'data.data')) {
    return get(categoryData, 'data.data', null);
  }

  return get(categoryData, 'data', null);
};

export const processQueryResult = (result, successCb) => {
  if (get(result, 'error.length')) {
    return (
      get(result, 'error[0].message') || i18n.t('common.defaultErrorMessage')
    );
  }
  successCb();
};

export const getErrorMessage = (errors, data, defaultMessage) => {
  let retMessage = defaultMessage;

  if (errors) {
    retMessage = get(errors, 'message', defaultMessage);
  } else if (data.errors) {
    retMessage = get(data, 'errors[0].message', defaultMessage);
  }

  return retMessage;
};

export const getMinMaxDateRangeFromEnrolmentConfig = enrolmentPeriod => ({
  minDate: moment().add(1, 'M').startOf('month'),
  maxDate: moment().add(parseInt(enrolmentPeriod, 10), 'M').endOf('month'),
});

export const handleDisabledDates = (date, allowDates = []) => {
  if (allowDates.includes(date._d.getDate())) {
    return false;
  }

  return true;
};

export const getAvailableMaritalStatuses = currentStatus => {
  switch (currentStatus) {
    case 'single':
      return ['single', 'married'];
    case 'married':
      return ['married', 'widowed', 'divorced', 'separated'];
    case 'widowed':
      return ['widowed', 'married'];
    case 'divorced':
      return ['divorced', 'married'];
    case 'separated':
      return ['separated', 'married', 'widowed', 'divorced'];
    default:
      return ['single', 'married', 'widowed', 'divorced', 'separated'];
  }
};

export const validateTimeRange = (time, validTimeRange) => {
  let error = null;
  let success = true;
  if (get(validTimeRange, 'from') && get(validTimeRange, 'to')) {
    const inputedTime = moment(time, HR_24_FORMAT);
    const from = moment(
      get(validTimeRange, 'from').split(' ')[1],
      HR_24_FORMAT
    );
    const to = moment(get(validTimeRange, 'to').split(' ')[1], HR_24_FORMAT);

    if (!inputedTime.isBetween(from, to)) {
      success = false;
      error = {
        notInBetween: {
          errorMessage: i18n.t('error.timeInvalid', {
            context: 'notInBetween',
            from: from.format(HR_24_AM_PM),
            to: to.format(HR_24_AM_PM),
          }),
          from: from.format(HR_24_AM_PM),
          to: to.format(HR_24_AM_PM),
        },
      };
    }
  } else if (get(validTimeRange, 'from')) {
    const inputedTime = moment(time, HR_24_FORMAT);
    const from = moment(
      get(validTimeRange, 'from').split(' ')[1],
      HR_24_FORMAT
    );

    if (!inputedTime.isAfter(from)) {
      success = false;
      error = {
        notAfter: {
          errorMessage: i18n.t('error.timeInvalid', {
            context: 'notAfter',
            from: from.format(HR_24_AM_PM),
          }),
          from: from.format(HR_24_AM_PM),
        },
      };
    }
  } else if (get(validTimeRange, 'to')) {
    const inputedTime = moment(time, HR_24_FORMAT);
    const to = moment(get(validTimeRange, 'to').split(' ')[1], HR_24_FORMAT);

    if (!inputedTime.isBefore(to)) {
      success = false;
      error = {
        notBefore: {
          errorMessage: i18n.t('error.timeInvalid', {
            context: 'notBefore',
            to: to.format(HR_24_AM_PM),
          }),
          to: to.format(HR_24_AM_PM),
        },
      };
    }
  }

  return {
    success,
    error,
  };
};

export const getMonthDuration = (month = 24) => {
  return Array.from({ length: month }, (_, i) => i + 1);
};

export const getIsRecentlyEmployed = effectiveDate =>
  moment().diff(moment(effectiveDate), 'month', true) <= 2;

export const getChildEnrolmentStatus = (
  currentLevel,
  upcomingLevel,
  enrolmentDate,
  viewingUserCentreAccesses,
  latestLevel
) => {
  let status = ENQUIRED;

  if (get(viewingUserCentreAccesses, 'length', 0) === 0) {
    return null;
  }

  const currentCentreAccess = viewingUserCentreAccesses.find(
    access => get(access, 'centre.ID') === get(currentLevel, 'centre.ID')
  );

  if (
    Boolean(get(currentLevel, 'level.ID')) &&
    (!get(viewingUserCentreAccesses, '[0].centre') ||
      get(currentLevel, 'centre.ID') === get(currentCentreAccess, 'centre.ID'))
  ) {
    status = ENROLLED;
    if (moment(enrolmentDate).isAfter()) {
      status = ADMISSION_STATUS.REGISTERED;
    }
  } else if (
    Boolean(get(upcomingLevel, 'level.ID')) &&
    enrolmentDate &&
    moment().isBefore(moment(enrolmentDate)) &&
    (!get(viewingUserCentreAccesses, '[0].centre') ||
      get(upcomingLevel, 'centre.ID') === get(currentCentreAccess, 'centre.ID'))
  ) {
    status = TO_BE_ENROLLED;
  }

  if (
    !currentLevel &&
    get(latestLevel, 'to') &&
    moment().isSameOrAfter(moment(get(latestLevel, 'to')))
  ) {
    const moveReason = get(latestLevel, 'moveReason', '');
    switch (moveReason) {
      case CHILD_DETAIL.LEVEL_MOVE_REASON.WITHDRAWAL:
        status = WITHDRAWN;
        break;
      case CHILD_DETAIL.LEVEL_MOVE_REASON.GRADUATION:
        status = GRADUATED;
        break;
      case CHILD_DETAIL.LEVEL_MOVE_REASON.TEMP_WITHDRAWAL:
        status = TEMP_WITHDRAWN;
        break;
      default:
        status = WITHDRAWN;
        break;
    }
  }

  return status;
};

export const constructFullName = (
  firstName,
  lastName,
  isForInputField = false
) => {
  if (!firstName && !lastName) {
    return isForInputField ? '' : '-';
  }
  let fullName = '';
  if (firstName) {
    fullName = firstName;
  }
  if (lastName) {
    fullName = fullName.concat(` ${lastName}`);
  }
  return fullName.trim();
};

// function formatBytes(bytes, decimals = 2) {
//     if (!+bytes) return '0 Bytes'

//     const k = 1024
//     const dm = decimals < 0 ? 0 : decimals
//     const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

//     const i = Math.floor(Math.log(bytes) / Math.log(k))

//     return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
// }

export const formatBytes = (bytes, decimals = 0, value = 1024) => {
  if (bytes === 0) return '0 Bytes';

  const k = value;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  // eslint-disable-next-line no-restricted-properties
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const getFileUploadErrorMessage = (
  error,
  maxSize,
  customSizeError,
  customTypeError
) => {
  const { code, message } = error || {};
  switch (code) {
    case 'file-invalid-type':
      return customTypeError ?? i18n.t('error.invalidFileTypeError');
    case 'file-too-large':
      return (
        customSizeError ??
        i18n.t('error.invalidFileSizeError', {
          maxSize: formatBytes(maxSize),
        })
      );
    default:
      return message;
  }
};

/**
 * Verify staff portal
 */
export const isStaffPortal = () =>
  (!!process.env.REACT_APP_STAFF_PORTAL &&
    process.env.REACT_APP_STAFF_PORTAL.replace(/\/+$/, '') ===
      `${window.location.protocol}//${window.location.hostname}`) ||
  window.location.hostname.indexOf('staff') === 0;

export const getEndingCardNumber = (cardNumber, asteriskNumber = 0) => {
  const endingCardNumber = cardNumber.split('x').pop();
  switch (asteriskNumber) {
    case 4:
      return `**** ${endingCardNumber}`;
    case 12:
      return `**** **** **** ${endingCardNumber}`;
    default:
      return endingCardNumber;
  }
};

export const s3FileUploadWithCaptionHandler = async (
  { reqData, s3Url, s3Key, fileRef, caption, data = null },
  successCb,
  errorCb,
  getMimeTypeExternally
) => {
  let url;
  const extraData = data;
  await s3FileUploadHandler(
    reqData,
    s3Url,
    s3Key,
    fileRef,
    savedUrl => {
      url = savedUrl;
    },
    errorCb,
    getMimeTypeExternally,
    extraData
  );
  return successCb({
    savedUrl: url,
    caption,
    type: get(fileRef, 'type') || MIME_FILES.IMAGE,
    data,
  });
};

export const getAnnouncementDateTimeFormat = momentDate => {
  return momentDate
    .calendar(null, {
      lastDay: `[Yesterday] • hh:mm A`,
      sameDay: `[Today] • hh:mm A`,
      nextDay: `[Tomorrow] • hh:mm A`,
      lastWeek: DATE_TIME_FORMAT.ANNOUNCEMENT_FORMAT,
      nextWeek: DATE_TIME_FORMAT.ANNOUNCEMENT_FORMAT,
      sameElse: DATE_TIME_FORMAT.ANNOUNCEMENT_FORMAT,
    })
    .replace('at', '•');
};

export const convertBoolToNumberChar = status => {
  if (typeof status !== 'boolean') return null;
  return status ? '1' : '0';
};

export const getTranslatedLabelForString = (t, str, isApplicant) => {
  const transMap = {
    lastname: t(
      isApplicant
        ? 'registration.applicantNameInPassport'
        : 'registration.nameInPassport'
    ),
    identificationNo: t(
      isApplicant
        ? 'common.applicantIdentificationNo'
        : 'common.nricOrFINorPassportNumberLabel'
    ),
    nationality: t('common.citizenship'),
    maritalStatus: t('common.maritalStatus'),
    workplaceStaff: t('registration.memberOfOrganization'),
    dateOfBirth: t('common.dateOfBirth'),
    email: t('common.emailText'),
    mobilePhone: t('common.phoneNumber'),
    mobilePhoneCountryCode: t('common.phoneCountryCode'),
    workingStatus: t('registration.workingStatusLabel'),
    occupationalTitle: t('registration.occupationalTitle'),
    salariedMonthlyIncome: t('registration.AverageGrossMonthlyIncomeSalaried'),
    selfEmployedMonthlyIncome: t(
      'registration.AverageGrossMonthlyIncomeSelfEmployed'
    ),
    highestQualification: t('common.highestEducationLevel'),
    reasonOfUnemployment: t('registration.notWorkingApplicant'),
    typeOfSeparation: t('registration.separationStatus'),
    isRecentlyEmployed: t('registration.startEmploymentWithinLast2Months'),
    ReferredToWSG: t('registration.careerMatch'),
    hasNoa: t('registration.haveNoticeOfAssessmentIRAS'),
    hasCpf: t('registration.receiveCPFcontributions'),
    permanentResidenceStartDate: t('registration.dateResidency'),
    commencementDate: t('common.commencementDate'),
  };

  return transMap[str];
};

export const getUserReadableValues = (type, val, options, t) => {
  let retVal = val;
  const typeMap = {
    nationality: get(options, 'nationalityConfig') || [],
    maritalStatus: get(options, 'maritalStatusConfig') || [],
    workingStatus: get(options, 'workingStatusConfig') || [],
    occupationalTitle: get(options, 'occupationConfig') || [],
    reasonOfUnemployment: get(options, 'nonWorkingReasonConfig') || [],
    highestQualification: get(options, 'higherQualification') || [],
    typeOfSeparation: get(options, 'separationReasonConfig') || [],
    workplaceStaff: get(options, 'workplaceStaffConfig') || [],
  };

  if (get(typeMap, type)) {
    if (type === 'occupationalTitle') {
      const occTypesAvailable = Object.keys(typeMap[type]);
      occTypesAvailable.forEach(item => {
        if (get(typeMap, `${type}.${item}`)) {
          get(typeMap, `${type}.${item}`, []).forEach(occType => {
            if (get(occType, 'label') === val) {
              retVal = get(occType, 'description');
            }
          });
        }
      });
    } else {
      typeMap[type].forEach(item => {
        if (val === get(item, 'label') && get(item, 'description')) {
          retVal = item.description;
        }
      });
    }
  }

  if (type === 'dateOfBirth') {
    retVal = moment(val, DATE_FORMAT).format(DATE_FORMAT);
  }

  if (val === '0' || val === '1') {
    retVal = parseInt(val) ? t('common.yes') : t('common.no');
  }

  return retVal;
};

export const checkValidDate = val => val && !moment(val).isValid();

export const validateToDate = (
  fromDate,
  toDate,
  invalidMsg = 'Invalid Date',
  invalidToDateMsg = 'To Date must be equal or greater than From Date'
) => {
  return checkValidDate(toDate)
    ? invalidMsg
    : fromDate &&
        toDate &&
        moment(fromDate).isValid() &&
        moment(toDate).isValid() &&
        moment(toDate).isBefore(moment(fromDate)) &&
        invalidToDateMsg;
};

export const prettifyNationalityCopy = item => {
  let nationality = '';
  if (item === 'pr') {
    nationality = getCapitalisePureString(item);
  } else {
    nationality = upperFirst(item);
  }
  return nationality;
};

export const onKeyPress = (event, type) => {
  if (type === 'code' || type === 'phone') {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  } else if (type === 'number') {
    if (!/^\d{0,10}(\.\d{0,2})?$/.test(event.key)) {
      event.preventDefault();
    }
  } else if (type === 'alphaNumeric') {
    if (!/^[a-zA-Z0-9]*$/.test(event.key)) {
      event.preventDefault();
    }
  } else {
    return null;
  }
};

export const stripHtml = text => {
  if (text && typeof text === 'string') {
    return text.replace(/(<([^>]+)>)/gi, '');
  }
  return text;
};

export const getPosterUrl = src => {
  if (!src) return '';
  const video = document.createElement('video');
  const canvas = document.createElement('canvas');
  video.src = src;
  video.currentTime = 8;
  canvas.width = 1080;
  canvas.height = 960;
  canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
  return canvas.toDataURL('image/jpeg');
};

export const getProgramLabel = programType => {
  const program = PROGRAM_TYPES.find(item => item.type === programType);
  return get(program, 'label', '');
};

export const replaceNewLines = text => {
  let replacedText = '';
  if (typeof text !== 'string') {
    const textToString = text.toString();
    replacedText = textToString.replace(/\n/g, ' ').trim();
  } else {
    replacedText = text.replace(/\n/g, ' ').trim();
  }
  return replacedText;
};

export const filterObjectKeys = (source = {}, property = '') => {
  return Object.keys(source)
    .filter(objKey => objKey.includes(property))
    .reduce((obj, key) => {
      obj[key] = source[key];
      return obj;
    }, {});
};

export const transferUnauthorizedErrorMessage = (errors = '', message) => {
  let error = errors;
  if (isArray(errors)) {
    error = get(errors, '[0].message') || '';
  }
  if (error.includes('unauthorized')) return message;
  return error || DEFAULT_ERROR;
};

export const RoundMoney = amount => {
  return Math.round((amount + Number.EPSILON) * 100) / 100;
};

export const timeZoneConverter = (momentObject, timeZone) => {
  return moment(momentObject).utc().utcOffset(timeZone);
};

export const isValidDate = str => {
  const d = moment(str, 'D/M/YYYY');
  if (d == null || !d.isValid()) return false;

  return (
    str.indexOf(d.format('D/M/YYYY')) >= 0 ||
    str.indexOf(d.format('DD/MM/YYYY')) >= 0 ||
    str.indexOf(d.format('D/M/YY')) >= 0 ||
    str.indexOf(d.format('DD/MM/YY')) >= 0
  );
};

export const escapeFolderNameWithoutSpecificCharacters = folderName => {
  return (folderName || '').replace(/[\\/:*?"<>|^]/g, '');
};

export const isContainKey = (keys, value) => {
  if (typeof value !== 'string') return false;

  return !!keys.filter(key => value.match(new RegExp(key))).length;
};

export const getSchoolShortName = () => {
  // Default school is MFS
  let schoolShortName = 'MFS';

  if (process.env.REACT_APP_SCHOOL_ID === '2') {
    schoolShortName = 'LSH';
  } else if (process.env.REACT_APP_SCHOOL_ID === '3') {
    schoolShortName = 'TCC';
  }

  return schoolShortName;
};

export const getLogoPostfix = () => {
  // Default value is MFS logo postfix url
  let logoPostfix = 'logo@3x.png';

  if (process.env.REACT_APP_SCHOOL_ID === '2') {
    logoPostfix = 'logo@3x.jpg';
  } else if (process.env.REACT_APP_SCHOOL_ID === '3') {
    logoPostfix = 'logo.svg';
  }

  return logoPostfix;
};

export const lowerValueIndexs = (arr1, arr2) => {
  const result = [];
  arr1.forEach((v, index) => {
    if (v > -1 && v < arr2[index]) {
      result.push(index);
    }
  });
  return result;
};

export const getStatusColor = status => {
  let color = 'main';
  switch (status) {
    case REJECTED:
      color = 'error';
      break;
    case PUBLISHED:
      color = 'success';
      break;
    case DRAFT:
      color = 'draft';
      break;
    case PENDING:
      color = 'warning';
      break;
    case APPROVE:
      color = 'success';
      break;
    default:
      color = 'main';
  }
  return color;
};

export const getIndividualStatusColor = status => {
  return BASE_COLOR.status[status];
};

export const getAllMonths = () => {
  return Array.from({ length: 12 }, (_, i) => ({
    value: i + 1,
    label: moment().month(i).format(FULL_MONTH_FORMAT),
  }));
};

/**
 * Convert & Remove Html Tags To Text
 * @param {*} html html raw string
 * @returns new string without html tags
 */
export const convertHtmlToText = html => {
  if (!html) return html;
  return html.replace(
    /<[^>]*(>|$)|&nbsp;|&#34;|&#39;|&zwnj;|&raquo;|&laquo;|&gt;/g,
    ''
  );
};

export const getObervationImagesForPortfolioPDF = async (pdfData = {}) => {
  const observations = get(pdfData, 'stateDomains', []).filter(
    d => get(d, 'classActivities.length', 0) > 0
  );
  const observationImages = uniqBy(
    observations.reduce((acc, value) => {
      const classActivityImages = get(value, 'classActivities', []).reduce(
        (acc, obv) => {
          const images = get(obv, 'classActivityImages.data', [])
            .filter(
              x => x.urlPresigned && !get(x, 'type', '').includes('video')
            )
            .slice(0, 3);
          return acc.concat(images);
        },
        []
      );
      return acc.concat(classActivityImages);
    }, []),
    'ID'
  );

  const data = await Promise.all(
    observationImages.map(osvImg =>
      axios({
        url: osvImg.urlPresigned,
        responseType: 'arraybuffer',
        headers: {
          'Cache-Control': 'no-cache',
        },
      })
        .then(async ({ data: arrayBuffer }) => {
          const orientation = await getOrientation(
            new Blob([arrayBuffer])
          ).catch(() => 1);
          return {
            ...osvImg,
            orientation,
            buffer: Buffer.from(arrayBuffer),
          };
        })
        .catch(() => ({
          ...osvImg,
          orientation: 1,
          buffer: null,
        }))
    )
  );
  return data;
};

export const showActivityTypes = activityType => {
  switch (activityType) {
    case POST:
      return i18n.t('classActivity.post');
    case ALBUM:
      return i18n.t('classActivity.album');
    case FOLIETTE || OBSERVATION:
      return i18n.t('classActivity.foliette');
    case CHILD_AT_WORK:
      return i18n.t('classActivity.child@Work');
    case CHILD_WORK_SAMPLE:
      return i18n.t('classActivity.childWorkSample');
    default:
      return i18n.t('classActivity.post');
  }
};

export const getPssFormWidthForDifferentScreens = () => {
  const screenWidth = window.screen.availWidth;

  if (screenWidth >= 960 && screenWidth <= 1700) {
    return 11;
  }

  return 10;
};

// Function to return school logo dimensions based on school id
export const getImageStyle = (schoolId, from = PSS) => {
  if (schoolId === MFS_ID && from === PSS) {
    return {
      height: 80,
      width: 155,
    };
  }

  if (schoolId === LSH_ID && from === PSS) {
    return {
      height: 100,
      width: 79,
    };
  }

  if (schoolId === MFS_ID && from === PSS) {
    return {
      height: 80,
      width: 155,
    };
  }

  if (schoolId === LSH_ID && from === PSS) {
    return {
      height: 100,
      width: 79,
    };
  }

  if (schoolId === MFS_ID && from === 'questions') {
    return {
      height: 80,
      width: 155,
      marginBottom: window.innerWidth > 1200 ? 0 : 20,
    };
  }

  if (schoolId === LSH_ID && from === 'questions') {
    return {
      height: 100,
      width: 79,
      marginBottom: window.innerWidth > 1200 ? 0 : 20,
    };
  }

  return {
    height: 100,
    width: 80,
  };
};

export const getSchoolData = (schoolId = null, from = PSS) => {
  const MFSLogoPath = 'MFS/pss_mfs_logo.png';
  const LSHLogoPath = 'LSH/pss_lsh_logo.png';
  const imageStyle = getImageStyle(schoolId, from);
  let schoolLogo = '';
  const envUrl = process.env.REACT_APP_CDN_URL;

  if (schoolId === MFS_ID) {
    schoolLogo = `${envUrl}/${MFSLogoPath}`;
  } else if (schoolId === LSH_ID) {
    schoolLogo = `${envUrl}/${LSHLogoPath}`;
  }

  return {
    imageStyle,
    schoolLogo,
    envUrl,
  };
};
export const isObjectId = id =>
  isString(id) && size(id) === 24 && /^[0-9a-fA-F]+$/.test(id);

export const checkMSEnabled = (state = {}, key = 'is_ms_disabled') => {
  return (
    parseInt(get(state, `schoolConfig.list.asObject.${key}`) || '0', 10) === 0
  );
};

export const decodePssSurveyLink = url => {
  const base64EncodedString = url;
  const decodedbase64EncodedString = atob(base64EncodedString);
  const stripFromStartBase64EncodedString =
    decodedbase64EncodedString.substring(20);
  const stripFromEndBase64EncodedString =
    stripFromStartBase64EncodedString.slice(0, -15);
  const decodedValue = decodeURIComponent(
    atob(stripFromEndBase64EncodedString)
  );
  return decodedValue;
};

/**
 * This's util function for wrapping the redux action with feature flag
 * @param {*} featureFlagKey -> required, feature flag name
 * @param {*} options => {
 *    dispatch, => redux dispatch
 *    msHandler, => ms handler
 *    monoHandler, => monolith handler
 *    extraData => extraData
 * }
 */
export const actionHandlerWithFeatureFlag = (
  featureFlagKey = '',
  {
    dispatch,
    msHandler = () => {},
    monoHandler = () => {},
    extraData = {},
  } = {}
) => {
  const { client } = SplitReactSDK;
  const attributes = client.getAttributes() || {};
  const treatment = featureFlagKey
    ? client.getTreatment(featureFlagKey, attributes) || 'off'
    : 'off';
  const isFeatureEnabled = treatment === 'on';

  if (isFeatureEnabled) {
    return monoHandler(dispatch, extraData);
  }
  return msHandler(dispatch, extraData);
};

/**
 * The func will be a helper to transform data response for MS from axios request
 * @param {*} res => axios response
 * @param {*} isArray => boolean
 * @returns array or object
 */
export const retrieveListOrObject = (res, isArray = true) =>
  get(res, 'data.data') || (isArray ? [] : {});

/**
 * The func will be a helper to get/find obj from sources (array, object) and transform obj with specifics keys needed
 * @param {*} sources => array or object
 * @param {*} findById => value to find
 * @param {*} keys => list of properties key, it can be using with format 'ID:id' with `ID` is the target key and `id` is a target value from obj
 * @param {*} keySearch => custom key property to find from source, default is `id`
 * Eg: retrieveDataMapper([{id: 1, name: 'A'}], 1, ['name', 'customName:name']) => {name: 'A', customName: 'A'}
 * retrieveDataMapper([{id: 1, name: 'A'}], 'A', ['name', 'customName:name'], 'name') => {name: 'A', customName: 'A'}
 * @returns null / object
 */
export const retrieveDataMapper = (
  sources,
  findById,
  keys = [],
  keySearch = 'id'
) => {
  let item = null;
  if (isArray(sources)) {
    item = sources?.find(c => c[keySearch] === findById);
  } else if (isObject(sources)) {
    item = sources[findById];
  }
  if (!item) return null;
  return keys?.reduce((obj, key = '') => {
    if (key?.includes(':')) {
      const [keyMap, keyMapFrom] = (key || '').split(':');
      obj[keyMap] = item?.[keyMapFrom];
    } else obj[key === 'id' ? 'ID' : camelCase(key)] = item?.[key];
    return obj;
  }, {});
};

/**
 * The func will be a helper to get and transform obj with specifics keys needed
 * @param {*} sources => be an object
 * @param {*} keys => list of properties key, it can be using with format 'ID:id' with `ID` is the target key and `id` is a target value from obj
 * Eg: retrieveObjectMapper({id: 1, name: 'A', image_key_presigned_url: '/url'}, ['ID:id', 'name', 'imageKeyURL:image_key_presigned_url'])
 * => {ID: 1, name: 'A', imageKeyURL: '/url'}
 * @returns new object
 */
export const retrieveObjectMapper = (sources = {}, keys = []) => {
  return keys?.reduce((obj, key = '') => {
    if (key?.includes(':')) {
      const [keyMap, keyMapFrom] = (key || '').split(':');
      obj[keyMap] = get(sources, keyMapFrom);
    } else obj[camelCase(key)] = get(sources, key);
    return obj;
  }, {});
};

/**
 * The func will be a helper to transform sort variables from camelCase to snackCase
 * @param {*} sort => array of sorting variable: ['-updatedAt', 'createdAt']
 * @returns sort object => {sort: 'sort value'}
 * Eg: retrieveSortForMS(['-updatedAt', 'createdAt']) => { customSort: '-updated_at,created_at' }
 */
export const retrieveSortForMS = (
  sort = [],
  customSortField = 'customSort'
) => ({
  [customSortField ?? 'customSort']: (() =>
    sort
      ?.reduce((sortArr, sort) => {
        const sortStr = `${sort?.startsWith('-') ? '-' : ''}${snakeCase(sort)}`;
        sortArr.push(sortStr);
        return sortArr;
      }, [])
      .join(','))(),
});

export const optimizeMediaUrlMs = (
  media,
  imageServiceEnabled = false,
  source = false,
  isVideo = false
) => {
  if (!imageServiceEnabled || source || isVideo) {
    return get(media, 'urlPresigned');
  }
  return `${process.env.REACT_APP_IMAGE_SERVICE_MS_URL}/${
    MEDIA_DATA.RESIZE
  }?key=${MEDIA_DATA.RES_540x0}/${get(media, 'urlEncodedPresigned')}`;
};

export const getMediaUploadConfig = (media = {}, allConfigs = []) => {
  const imageConfigs = {
    quality:
      get(
        allConfigs.find(
          conf =>
            get(conf, 'key') === CLASS_ACTIVITY_IMAGE_COMPRESSION_PERCENT_TEXT
        ),
        'value'
      ) / 100 || CLASS_ACTIVITY_IMAGE_COMPRESSION_PERCENT / 100,
  };
  const { height, width } = media;
  if (width > height) {
    // landscape.
    return {
      maxWidth:
        get(
          allConfigs.find(
            conf =>
              get(conf, 'key') === CLASS_ACTIVITY_IMAGE_MAX_WIDTH_LANDSCAPE_TEXT
          ),
          'value'
        ) || CLASS_ACTIVITY_IMAGE_MAX_WIDTH_LANDSCAPE,
      maxHeight:
        get(
          allConfigs.find(
            conf =>
              get(conf, 'key') ===
              CLASS_ACTIVITY_IMAGE_MAX_HEIGHT_LANDSCAPE_TEXT
          ),
          'value'
        ) || CLASS_ACTIVITY_IMAGE_MAX_HEIGHT_LANDSCAPE,
      ...imageConfigs,
    };
  }
  return {
    maxWidth:
      get(
        allConfigs.find(
          conf =>
            get(conf, 'key') === CLASS_ACTIVITY_IMAGE_MAX_WIDTH_PORTRAIT_TEXT
        ),
        'value'
      ) || CLASS_ACTIVITY_IMAGE_MAX_WIDTH_PORTRAIT,
    maxHeight:
      get(
        allConfigs.find(
          conf =>
            get(conf, 'key') === CLASS_ACTIVITY_IMAGE_MAX_HEIGHT_PORTRAIT_TEXT
        ),
        'value'
      ) || CLASS_ACTIVITY_IMAGE_MAX_HEIGHT_PORTRAIT,
    ...imageConfigs,
  };
};
