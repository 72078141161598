/* eslint-disable no-sequences */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import { get, isEqual, find, filter, has, isNil } from 'lodash';
import { withTranslation } from 'react-i18next';
import {
  AppBar,
  Toolbar,
  Button,
  MenuItem,
  Menu,
  Tooltip,
  Badge,
  List,
  ClickAwayListener,
  Grid,
  Paper,
  Snackbar,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import compose from 'recompose/compose';
import { withTheme } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import LogoutIcon from '@material-ui/icons/DirectionsRun';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import NotificationBell from '@material-ui/icons/Notifications';
import Help from '@material-ui/icons/HelpOutline';
import { withRouter } from 'react-router-dom';
import { withAuth0 } from '@auth0/auth0-react';
import { LOGIN } from '../../routes/constants';
import { s3FileUploadWithCaptionHandler, showActivityTypes } from '../../utils';
import {
  fetchSidemenuConfig,
  markNotificationRead,
  fetchNotificationHistory,
  fetchImageUploader,
  changeCurrentAssetStatus,
  bulkClassActivityImagesUpload,
  showSnackBarMessage,
} from '../../redux/actions';
import mobileNavigation from '../../redux/actions/MobileNavigation';
import localStore from '../../utils/localStorage';
import Loader from './Loader';
import Notification from './Notification';
import { updateClassActivityStatus } from '../../redux/actions/ClassManagement';
import {
  ASSETSIN_TODO,
  ASSETSIN_COMPLETE,
  ASSETSIN_INPROGRESS,
  ASSETSIN_PROCESSING,
  PUBLISHED,
  POST,
  DRAFT,
  ALBUM,
  APPROVE,
  PENDING,
  OBSERVATION,
} from '../../utils/constants';

const Alert = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const handleFilesUploadToS3 = async (imageList, successCb, errorCb) => {
  if (!imageList.length) {
    return null;
  }

  const { s3Key, s3Url, fileRef, caption, data } = imageList.pop();
  const strIdx = s3Url.indexOf('?');
  const reqData = s3Url.substr(0, strIdx);

  await s3FileUploadWithCaptionHandler(
    { reqData, s3Url, s3Key, fileRef, caption, data },
    successCb,
    errorCb,
    data
  );
  return handleFilesUploadToS3(imageList, successCb, errorCb);
};

const statusChecker = (prevProps, newProps, updateStatus) => {
  const newAssets = isEqual(
    get(prevProps, 'assets.length', 0),
    get(newProps, 'assets.length', 0)
  );
  const isTodoOrDone =
    get(newProps, 'mainStatus') === ASSETSIN_TODO ||
    get(newProps, 'mainStatus') === ASSETSIN_COMPLETE;
  const isAnyAssetsPendingtoUpdate = find(
    get(newProps, 'assets', []),
    e => e.status === ASSETSIN_TODO
  );
  if (!newAssets && isTodoOrDone && isAnyAssetsPendingtoUpdate) {
    const assets = get(newProps, 'assets');
    const updateResponse = updateStatus(assets, 'inProgress');
    if (updateResponse) {
      return true;
    }
  }
  return false;
};

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      pointedElement: null,
      notificationData: null,
    };

    this.logoutUser = this.logoutUser.bind(this);
    this.handleMenu = this.handleMenu.bind(this);
    this.fetchAssetsUpload = this.fetchAssetsUpload.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.openNotification = this.openNotification.bind(this);
    this.onBeforeUnload = this.onBeforeUnload.bind(this);
    this.sidemenufetched = false;
    this.readTimeout = null;
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.onBeforeUnload);
    const { fetchNotification } = this.props;
    fetchNotification().then(data => {
      if (get(data, 'data.getNotifications')) {
        this.timer = setInterval(fetchNotification, 1800000);
      }
    });
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const {
      notificationHistory,
      classActivityListToUpload,
      changeUploadStatus,
    } = this.props;

    // statusChecker -> stimulate upload function -> validating is any new assets th
    if (
      statusChecker(
        classActivityListToUpload,
        newProps.classActivityListToUpload,
        changeUploadStatus
      )
    ) {
      this.fetchAssetsUpload(newProps.classActivityListToUpload);
    }

    if (
      !isEqual(notificationHistory, newProps.notificationHistory) &&
      !get(newProps, 'notificationHistory.inProgress')
    ) {
      this.setState({
        notificationData: newProps.notificationHistory,
      });
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { fetchSidemenuData, fkSchool } = this.props;

    fetchSidemenuData(fkSchool);
  }

  onBeforeUnload(e) {
    const { classActivityListToUpload } = this.props;
    if (
      get(classActivityListToUpload, 'mainStatus') === ASSETSIN_TODO ||
      get(classActivityListToUpload, 'mainStatus') === ASSETSIN_INPROGRESS
    ) {
      e.preventDefault();
      e.returnValue = '';
      return;
    }
    delete e.returnValue;
  }

  handleMenu() {
    let anchorEle = null;
    if (document && document.getElementById) {
      anchorEle = document.getElementById('menu-dropdown-anchor');
    }
    this.setState({ anchorEl: anchorEle });
  }

  handleImageUploads = async (
    images = [],
    IDClassActivity,
    status = ASSETSIN_PROCESSING,
    assetID = null
  ) => {
    const { uploadImages } = this.props;
    const imageUploadData = [];
    if (get(images, 'length', 0) > 0) {
      images.forEach(eachImage => {
        const processedObject = {
          IDClassActivity,
          imageDto: {
            Caption: get(eachImage, 'caption', ''),
            Status: 'public',
            StatusProcess: status,
            Type: get(eachImage, 'file.type'),
            ...(!isNil(get(eachImage, 'index')) && {
              Index: get(eachImage, 'index'),
            }),
          },
          file: {
            ...get(eachImage, 'file'),
            fileName: get(eachImage, 'file.name'),
            MIMEType: get(eachImage, 'file.type'),
            fileSize: get(eachImage, 'file.size'),
          },
        };

        if (!isNil(assetID)) {
          processedObject.ClassActivityMediaID = assetID;
        }

        imageUploadData.push(processedObject);
      });
    }
    const imagesResp = await uploadImages(imageUploadData);
    if (!imagesResp.success) {
      return false;
    }
    return imagesResp.data;
  };

  saveImagesToActivity = async (activityId, postDetails) => {
    const { showSnackBar } = this.props;
    let savedImages = {};
    const imagesArr = get(postDetails, 'images', []);

    if (!activityId || !get(imagesArr, 'length')) {
      return [];
    }

    const newImages = imagesArr.filter(eachImage =>
      Boolean(eachImage.file.size)
    );
    if (get(newImages, 'length', 0) > 0) {
      savedImages = await this.handleImageUploads(newImages, activityId);
      if (!savedImages) {
        return false;
      }
    } else {
      return imagesArr;
    }

    const imageKeys = Object.keys(savedImages);
    const Images = imagesArr.filter(eachImage => !eachImage.file.size);
    if (imageKeys.length) {
      const s3ImageList = imageKeys.map((currVal, idx) => ({
        s3Key: savedImages[currVal].UploadInfo.Key,
        s3Url: savedImages[currVal].UploadInfo.Url,
        fileRef: newImages[idx].file,
        caption: get(newImages[idx], 'caption'),
        data: {
          classAcitivityID: get(
            savedImages[currVal],
            'ClassActivityImage.fkClassActivity',
            activityId
          ),
          assetID: get(savedImages[currVal], 'ClassActivityImage.ID'),
          activityId,
          assets: newImages[idx],
        },
      }));
      await handleFilesUploadToS3(
        s3ImageList,
        async ({
          savedUrl,
          caption,
          type,
          data: { assets, classAcitivityID, assetID },
        }) => {
          if (type.includes('image')) {
            await this.handleImageUploads(
              [assets],
              classAcitivityID,
              'published',
              assetID
            );
          }
          Images.push({
            URL: savedUrl,
            Status: 'public',
            caption,
            type,
          });
        },
        async (url, er, data) => {
          await this.handleImageUploads(
            [get(data, 'assets', [])],
            get(data, 'classAcitivityID'),
            'fail',
            get(data, 'assetID')
          );
          showSnackBar(
            `Class Activity assets -> ${url} upload Failed due to ${er}`,
            'error'
          );
        }
      );
    }
    return Images;
  };

  showObservationToast = (
    t,
    actionStatus = PENDING,
    activityType = OBSERVATION
  ) => {
    switch (actionStatus) {
      case PUBLISHED:
        return t('classActivity.activityPublishedSuccessfully', {
          activityType: showActivityTypes(activityType),
        });
      case APPROVE:
        return t('classActivity.activityApproveSnack', {
          activityType: showActivityTypes(activityType),
        });
      default:
        return t('classActivity.activitySubmittedSuccessfully', {
          activityType: showActivityTypes(activityType),
        });
    }
  };

  async fetchAssetsUpload(classActivityListToUpload) {
    const { showSnackBar, changeAssetsStatus, updateClassActivityStatus, t } =
      this.props;
    try {
      const isTodoList = find(
        get(classActivityListToUpload, 'assets'),
        e => e.status === ASSETSIN_TODO
      );
      const draftList = filter(
        get(classActivityListToUpload, 'assets'),
        e => e.status === ASSETSIN_TODO
      );

      if (!isTodoList && !get(draftList, 'length')) {
        return;
      }

      await draftList.forEach(async eachActivity => {
        try {
          const assetsStatusResponse = changeAssetsStatus(
            get(classActivityListToUpload, 'assets'),
            get(eachActivity, 'actId'),
            ASSETSIN_INPROGRESS
          );
          if (!assetsStatusResponse) {
            return;
          }

          const savedImageList = await this.saveImagesToActivity(
            get(eachActivity, 'actId'),
            get(eachActivity, 'postDetails')
          );
          const isAllAssetsUploaded =
            get(savedImageList, 'length') ===
            get(eachActivity, 'postDetails.images.length');

          if (!isAllAssetsUploaded) {
            throw new Error(
              t('classActivity.corruptedFile', {
                length: get(savedImageList, 'length') + 1,
              })
            );
          }

          const activityTitle = get(eachActivity, 'postDetails.title', '');
          const activityType = get(
            eachActivity,
            'reqData.variables.dto.Type',
            POST
          );
          const hasVideo = get(eachActivity, 'postDetails.images').some(
            eachMedia =>
              Boolean(eachMedia.file.size) &&
              get(eachMedia, 'type', '').includes('video')
          );
          if (!hasVideo) {
            const updateClassActivityStatusCall =
              await updateClassActivityStatus({
                variables: {
                  IDClassActivity: get(eachActivity, 'actId'),
                  status: get(eachActivity, 'action'),
                },
              });
            if (
              !has(
                updateClassActivityStatusCall,
                'data.updateClassActivityStatus.ID'
              )
            ) {
              throw new Error(t('classActivity.statusUpdateFail'));
            }

            const actionStatus = get(eachActivity, 'action', DRAFT);
            const actionType =
              actionStatus === PUBLISHED
                ? t('classActivity.activityTypePublish')
                : t('classActivity.activityTypeDraft');

            if (actionStatus !== DRAFT) {
              if ([POST, ALBUM].includes(activityType)) {
                showSnackBar(
                  t('classActivity.successWithActionText', {
                    activityType: showActivityTypes(activityType),
                    actionType,
                    activityTitle,
                  })
                );
              } else {
                const observationToast = this.showObservationToast(
                  t,
                  actionStatus,
                  activityType
                );
                showSnackBar(observationToast);
              }
            }
          } else if ([POST, ALBUM].includes(activityType)) {
            showSnackBar(
              t('classActivity.videoProcessText', {
                activityTitle,
              })
            );
          }
          const assetsStatusChange = await changeAssetsStatus(
            get(classActivityListToUpload, 'assets'),
            get(eachActivity, 'actId'),
            ASSETSIN_COMPLETE
          );

          if (!assetsStatusChange) {
            throw new Error(t('classActivity.uploadCorrupted'));
          }
        } catch (err) {
          showSnackBar(t('classActivity.failedErrorText1', { err }), 'error');
        }
      });
    } catch (er) {
      showSnackBar(t('classActivity.failedErrorText1', { er }), 'error');
    }
  }

  handleClose() {
    this.setState({ anchorEl: null });
  }

  logoutUser() {
    const { history, auth0 } = this.props;
    const { logout, isAuthenticated } = auth0;
    localStore.setValue('linkpassIDToken', null);
    localStore.setValue('userToken', null);
    localStore.setValue('selectedChild', null);
    localStore.setValue('themeData', null);
    localStore.setValue('schoolID', null);
    localStore.setValue('registerToken', null);
    localStore.setValue('linkFailError', false);
    Cookies.remove('epUserToken', {
      path: '',
      domain: process.env.REACT_APP_DOMAIN || '.skool.sg',
    });
    if (isAuthenticated) {
      logout({
        returnTo: window.location.origin,
      });
    } else {
      history.push(LOGIN);
    }
  }

  openNotification(e) {
    const { pointedElement, notificationData } = this.state;
    let elementType = null;

    if (pointedElement === null) {
      elementType = e.target;
    }

    this.setState({ pointedElement: elementType });

    const { notificationsRead, fetchNotification } = this.props;
    const unreadNotificationIds = (get(notificationData, 'data.data') || [])
      .filter(x => !x.read)
      .map(x => x.ID);

    if (unreadNotificationIds.length && pointedElement) {
      notificationsRead(unreadNotificationIds).then(resp => {
        if (get(resp, 'data.markNotifications')) {
          fetchNotification();
        }
      });
    }
  }

  showMenu = event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    const { mobileNavigation } = this.props;
    mobileNavigation(true);
  };

  render() {
    const { anchorEl, pointedElement, notificationData } = this.state;
    const open = Boolean(anchorEl);
    const openPop = Boolean(pointedElement);
    const {
      userDetails,
      theme,
      fkSchool,
      freshDeskSupportUrl,
      isStaff,
      classActivityListToUpload,
    } = this.props;
    const headerData = {
      userName: 'User',
    };

    headerData.userName = `${userDetails.firstname} ${userDetails.lastname}`;

    const notificationCount = (
      get(notificationData, 'data.data', []) || []
    ).filter(x => !x.read).length;
    let notificationList = null;
    if (get(notificationData, 'inProgress')) {
      notificationList = (
        <>
          <Loader />
        </>
      );
    } else {
      notificationList = (
        <Notification
          notificationData={get(notificationData, 'data.data', [])}
        />
      );
    }

    const notificationHistoryButton = (
      <Button
        id="pop"
        color="inherit"
        onClick={this.openNotification}
        style={{ position: 'relative' }}
        className={
          openPop
            ? 's7t-notification-Button s7t-button-border'
            : 's7t-notification-Button'
        }
      >
        <Tooltip title="Notifications">
          <Badge id="badge" badgeContent={notificationCount} color="primary">
            <NotificationBell style={{ fontSize: '29px' }} />
          </Badge>
        </Tooltip>
        {openPop && (
          <ClickAwayListener onClickAway={this.openNotification}>
            <Paper elevation={2} className="s7t-notification">
              <div className="s7t-arrow" />
              <Grid container>
                <Grid
                  item
                  xs={12}
                  className="s7t-scroll-container overflow-x-hidden"
                >
                  <List className="s7t-list">{notificationList}</List>
                </Grid>
              </Grid>
            </Paper>
          </ClickAwayListener>
        )}
      </Button>
    );

    return (
      <>
        <AppBar
          position="static"
          className="w3-padding s7t-header"
          style={{ backgroundColor: theme.headerColor }}
        >
          <span id="menu-dropdown-anchor" />
          <Toolbar>
            <div className="s7t-title">
              <Hidden only={['xs']}>
                {isStaff && (
                  <Tooltip title="FAQ">
                    <a
                      href={freshDeskSupportUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button
                        id="pop"
                        color="inherit"
                        style={{
                          position: 'relative',
                          color: '#fff',
                        }}
                      >
                        <Help style={{ fontSize: '29px' }} />
                      </Button>
                    </a>
                  </Tooltip>
                )}
                {notificationHistoryButton}
                <Button
                  className="header-logout"
                  aria-owns={open ? 'menu-appbar' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleMenu}
                  color="inherit"
                >
                  <PersonIcon
                    style={{
                      fontSize: '29px',
                      marginRight: '10px',
                    }}
                  />
                  {headerData.userName}
                  <ArrowDropDownIcon />
                </Button>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={this.handleClose}
                >
                  <MenuItem
                    onClick={() => this.logoutUser(fkSchool)}
                    className="s7t-text-brownish-grey"
                  >
                    <LogoutIcon className="s7t-menu-item-img" />
                    Log out
                  </MenuItem>
                </Menu>
              </Hidden>
              <Hidden only={['xl', 'lg', 'md', 'sm']}>
                <Button onClick={event => this.showMenu(event)}>
                  <MenuIcon color="secondary" fontSize="large" />
                </Button>
              </Hidden>
            </div>
          </Toolbar>
        </AppBar>
        {get(classActivityListToUpload, 'assets.length') &&
          get(classActivityListToUpload, 'mainStatus') === 'inProgress' && (
            <Snackbar
              open
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
              <Alert severity="error">Uploading in Progress</Alert>
            </Snackbar>
          )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  classActivityListToUpload: get(state, 'getAllUploadingAssets.data', null),
  fkSchool: get(
    state,
    'userDetails.data.userAccessControls.data[0].school.ID',
    3
  ),
  notificationHistory: get(state, 'getNotificationHistory'),
  freshDeskSupportUrl: get(state, 'freshDeskSupportUrl.data'),
  isStaff: get(
    state,
    'userDetails.data.userRoleRelations.data[0].role.isStaff',
    false
  ),
});

const mapDispatchToProps = {
  uploadImages: bulkClassActivityImagesUpload,
  fetchSidemenuData: fetchSidemenuConfig,
  fetchNotification: fetchNotificationHistory,
  notificationsRead: markNotificationRead,
  changeUploadStatus: fetchImageUploader,
  changeAssetsStatus: changeCurrentAssetStatus,
  mobileNavigation,
  showSnackBar: showSnackBarMessage,
  updateClassActivityStatus,
};

export default compose(
  withTranslation(),
  withTheme
)(withRouter(connect(mapStateToProps, mapDispatchToProps)(withAuth0(Header))));
