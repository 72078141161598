import { get } from 'lodash';
import { MutationBuilder, QueryBuilder } from '../../queryBuilder';
import SkRequest from '../BaseRequest';
import {
  FETCH_ENROLMENT_FORM,
  FETCH_ENQUIRY_BY_ID,
  FETCH_ADDITIONAL_INFO,
  REG_ENROLMENT_FORM,
  ECDA_REG_ENROLMENT_FORM,
  FETCH_ENQUIRY_BY_ID_ECDAMVP,
} from '../actionTypes';
import {
  START_REGISTRATION,
  GET_REGISTRATION_BY_ID,
  GET_REG_ENROLMENT_FORM,
  SAVE_REG_ENROLMENT_FORM_GQL,
  GET_CONFIRMATION_FOR_SPOUSE,
  GET_REGISTRATION_STATUS_BY_ID,
  GET_REGISTRATION_BY_ID_ECDAMVP,
  GET_ECDA_REG_ENROLMENT_FORM,
  SAVE_ECDA_REG_ENROLMENT_FORM_GQL,
  START_ECDA_REGISTRATION,
  REQUIRE_CONFIRMATION_FOR_ECDA_SPOUSE,
} from '../../models/enrolmentModel';
import { SkApolloRequest } from '../../../utils/apolloUtils';

const mMutationBuilder = new MutationBuilder();
const mQueryBuilder = new QueryBuilder();

mMutationBuilder.setNewMutation('saveEnrolmentForm', {});

mMutationBuilder.setNewMutation('saveAdditionalInformation', {});

mQueryBuilder.setNewQuery('getAdditionalInformation', {
  attendingOtherSchool: 'bool',
  specialInstruction: {
    totalChildrenInFamily: 'number',
    birthOrder: 'number',
    isToiletTrained: 'bool',
    solidFoodAllowed: 'bool',
    childAffinity: 'string',
    languageSpokenAtHome: 'string',
    remarks: 'string',
  },
  houseHoldParticulars: {
    householdCount: 'number',
    fatherEducationLevel: 'string',
    fatherEducationOthers: 'string',
    motherEducationLevel: 'string',
    motherEducationOthers: 'string',
  },
  healthInformation: {
    isImmunized: 'bool',
    immunizationDetails: 'string',
    familyDoctorDetails: {
      name: 'string',
      contactNumber: 'string',
      countryCode: 'string',
      clinicName: 'string',
      clinicBuilding: 'string',
      clinicFloor: 'string',
      clinicUnit: 'string',
      clinicCity: 'string',
      clinicPostalCode: 'string',
    },
    medicalCondition: {
      disease: 'string',
      exists: 'bool',
      remarks: 'string',
    },
    medicalConditionRemarks: 'Sstring',
    specialNeeds: 'bool',
  },
  declaration: {
    termsAndCondition: 'bool',
    termsCDN: 'bool',
  },
});

mQueryBuilder.setNewQuery('registrationByID', {
  ID: 'number',
  fkUser: 'number',
  registrationStartDate: 'timestamp',
  registrationDeadline: 'timestamp',
  state: 'string',
  consentStatus: 'string',
  documentStatus: 'string',
  createdAt: 'timestamp',
  updatedAt: 'timestamp',
  active: 'bool',
  paymentStatus: 'string',
  giroStatus: 'string',
  isApplicationSubmitted: 'bool',
  currentFrontendStatus: 'string',
  documentNotificationSent: 'string',
  forms: {
    data: {
      ID: 'number',
      eDocumentStatus: 'string',
      fkEnquiry: 'number',
      formParticipants: {
        data: {
          ID: 'number',
          fkForm: 'number',
          parent: {
            firstname: 'string',
            lastname: 'string',
            mobilePhone: 'string',
          },
          user: {
            firstname: 'string',
            lastname: 'string',
          },
          displayName: 'string',
          order: 'number',
          email: 'string',
          status: 'string',
          updatedAt: 'datetime',
        },
      },
    },
  },
  registrationChildMappings: {
    data: {
      ID: 'number',
      fkLevel: 'number',
      fkCentre: 'number',
      fkProgram: 'number',
      enrolmentDate: 'timestamp',
      registrationChild: {
        ID: 'number',
        fkChild: 'number',
        firstname: 'string',
        lastname: 'string',
        race: 'string',
        nationality: 'string',
        dateOfBirth: 'timestamp',
        gender: 'string',
        birthCertificate: 'string',
        householdIncomeRange: 'string',
        registrationChildParents: {
          data: {
            ID: 'number',
            fkRelationship: 'number',
            registrationParent: {
              identificationNo: 'string',
              workplaceStaff: 'string',
              firstname: 'string',
              lastname: 'string',
              ID: 'number',
              mobilePhone: 'string',
              mobilePhoneCountryCode: 'string',
              email: 'string',
              addresses: {
                totalCount: 'number',
                data: {
                  fkUser: 'number',
                  address: 'string',
                  building: 'string',
                  floor: 'string',
                  unit: 'string',
                  city: 'string',
                  country: 'string',
                  postcode: 'number',
                },
              },
            },
          },
        },
      },
      centre: {
        ID: 'number',
        label: 'string',
        code: 'string',
        payNowEmail: 'string',
        addresses: {
          totalCount: 'number',
          data: {
            ID: 'number',
            name: 'string',
            address: 'string',
            postcode: 'number',
            fkCentre: 'number',
            lat: 'number',
            lng: 'number',
            building: 'string',
            floor: 'string',
            unit: 'string',
            city: 'string',
          },
        },
      },
      program: {
        ID: 'number',
        label: 'string',
      },
      level: {
        ID: 'number',
        label: 'string',
      },
    },
  },
});

mQueryBuilder.setNewQuery('enquiryByID', {
  ID: 'number',
  fkCentre: 'number',
  fkUser: 'number',
  fkLevel: 'number',
  enrolmentStartDate: 'timestamp',
  enrolmentDueDate: 'timestamp',
  fkProgram: 'number',
  enrolmentStep: 'string',
  fkEnquiryState: 'string',
  firstname: 'string',
  lastname: 'string',
  consentStatus: 'string',
  documentStatus: 'string',
  enrollmentPlan: 'timestamp',
  createdAt: 'timestamp',
  updatedAt: 'timestamp',
  documentNotificationSent: 'bool',
  householdIncomeRange: 'string',
  active: 'bool',
  paymentStatus: 'string',
  giroStatus: 'string',
  vacancyAvailable: 'bool',
  privateVacancyAvailable: 'bool',
  isTransferEnquiry: 'bool',
  isTransferEnquiryReadyForEnrolment: 'bool',
  applicationSubmitted: 'bool',
  workplaceStaff: 'string',
  admissionStatus: {
    label: 'string',
    stateCode: 'string',
  },
  child: {
    ID: 'number',
    firstname: 'string',
    lastname: 'string',
    race: 'string',
    nationality: 'string',
    dateOfBirth: 'timestamp',
    gender: 'string',
    birthCertificate: 'string',
    childRelations: {
      data: {
        fkRelation: 'number',
      },
    },
  },
  centre: {
    ID: 'number',
    label: 'string',
    code: 'string',
    payNowEmail: 'string',
    addresses: {
      totalCount: 'number',
      data: {
        ID: 'number',
        name: 'string',
        address: 'string',
        postcode: 'number',
        fkCentre: 'number',
        lat: 'number',
        lng: 'number',
        building: 'string',
        floor: 'string',
        unit: 'string',
        city: 'string',
      },
    },
  },
  visits: {
    totalCount: 'number',
    data: {
      ID: 'number',
      status: 'string',
      visitTo: 'string',
      visitFrom: 'string',
      visitType: 'string',
      centre: {
        label: 'string',
      },
    },
  },
  program: {
    ID: 'number',
    label: 'string',
  },
  user: {
    firstname: 'string',
    lastname: 'string',
    ID: 'number',
    gender: 'string',
    mobilePhone: 'string',
    mobilePhoneCountryCode: 'string',
    homePhone: 'string',
    email: 'string',
    addresses: {
      totalCount: 'number',
      data: {
        fkUser: 'number',
        address: 'string',
        building: 'string',
        floor: 'string',
        unit: 'string',
        city: 'string',
        country: 'string',
        postcode: 'number',
      },
    },
    parent: {
      identificationNo: 'string',
    },
  },
  level: {
    ID: 'number',
    label: 'string',
  },
  forms: {
    data: {
      ID: 'number',
      eDocumentStatus: 'string',
      fkEnquiry: 'number',
      formParticipants: {
        data: {
          ID: 'number',
          fkForm: 'number',
          parent: {
            firstname: 'string',
            lastname: 'string',
            mobilePhone: 'string',
          },
          user: {
            firstname: 'string',
            lastname: 'string',
          },
          displayName: 'string',
          order: 'number',
          email: 'string',
          status: 'string',
          updatedAt: 'datetime',
        },
      },
    },
  },
});

mQueryBuilder.setNewQuery('getEnrolmentForm', {
  Step: 'number',
  IDSchool: 'number',
  Enquiry: {
    enrollmentPlan: 'datetime',
    applyFinancialAssistance: 'bool',
    householdIncomeRange: 'string',
    hasSug: 'bool',
    hasFinancialAssistance: 'bool',
    typeOfReferral: 'string',
    referralChannel: 'string',
    financialStartDate: 'datetime',
    financialEndDate: 'datetime',
    isDeclared: 'bool',
    isAcknowledged: 'bool',
    otherReferralChannel: 'string',
  },
  ChildParticulars: {
    IDChild: 'number',
    Child: {
      firstname: 'string',
      lastname: 'string',
      nationality: 'string',
      gender: 'string',
      race: 'string',
      dateOfBirth: 'datetime',
      birthCertificate: 'string',
      numFamilyMembers: 'number',
      numDependents: 'number',
      isBigHousehold: 'boolean',
      hasEmergencyContact: 'boolean',
      supportScheme: 'string',
    },
  },
  Applicant: {
    Applicant: {
      firstname: 'string',
      lastname: 'string',
      email: 'string',
      mobilePhoneCountryCode: 'string',
      mobilePhone: 'string',
      birthdate: 'string',
      gender: 'string',
    },
    ApplicantAddress: {
      address: 'string',
      building: 'string',
      floor: 'string',
      unit: 'string',
      postcode: 'string',
    },
    ApplicantDetail: {
      firstname: 'string',
      lastname: 'string',
      email: 'string',
      mobilePhoneCountryCode: 'string',
      mobilePhone: 'string',
      birthdate: 'string',
      gender: 'string',
      typeOfSeparation: 'string',
      identificationNo: 'string',
      monthlyIncome: 'string',
      selfEmployedMonthlyIncome: 'string',
      workingStatus: 'string',
      maritalStatus: 'string',
      highestQualification: 'string',
      occupationalTitle: 'string',
      nationality: 'string',
      isEmployed: 'bool',
      selfEmployed: 'bool',
      isRecentlyEmployed: 'bool',
      reasonOfUnemployment: 'string',
      referredToWsg: 'bool',
      isApplicantSpouse: 'bool',
      commencementDate: 'datetime',
      permanentResidenceStartDate: 'datetime',
      hasNoa: 'bool',
      hasCpf: 'bool',
      isNameInBc: 'bool',
    },
    ApplicantRelationshipToChild: {
      fkRelation: 'number',
      fkChild: 'number',
    },
    ApplicantGuardian: {
      firstname: 'string',
      lastname: 'string',
      relationship: 'string',
      citizen: 'string',
      dateOfBirth: 'datetime',
      identificationNumber: 'string',
    },
  },
  Spouse: {
    Applicant: {
      firstname: 'string',
      lastname: 'string',
      email: 'string',
      mobilePhoneCountryCode: 'string',
      mobilePhone: 'string',
      birthdate: 'string',
      gender: 'string',
    },
    ApplicantAddress: {
      address: 'string',
      building: 'string',
      floor: 'string',
      unit: 'string',
      postcode: 'string',
    },
    ApplicantDetail: {
      firstname: 'string',
      lastname: 'string',
      email: 'string',
      mobilePhoneCountryCode: 'string',
      mobilePhone: 'string',
      birthdate: 'string',
      gender: 'string',
      identificationNo: 'string',
      monthlyIncome: 'string',
      selfEmployedMonthlyIncome: 'string',
      workingStatus: 'string',
      maritalStatus: 'string',
      highestQualification: 'string',
      occupationalTitle: 'string',
      nationality: 'string',
      isEmployed: 'bool',
      selfEmployed: 'bool',
      isRecentlyEmployed: 'bool',
      reasonOfUnemployment: 'string',
      referredToWsg: 'bool',
      isApplicantSpouse: 'bool',
      commencementDate: 'datetime',
      permanentResidenceStartDate: 'datetime',
      hasNoa: 'bool',
      hasCpf: 'bool',
    },
    ApplicantRelationshipToChild: {
      fkRelation: 'number',
      fkChild: 'number',
    },
    ApplicantGuardian: {
      firstname: 'string',
      lastname: 'string',
      relationship: 'string',
      citizen: 'string',
      dateOfBirth: 'datetime',
      identificationNumber: 'string',
    },
  },
  Guardians: {
    Guardian: {
      firstname: 'string',
      lastname: 'string',
      email: 'string',
      mobilePhoneCountryCode: 'string',
      mobilePhone: 'string',
      birthdate: 'string',
      gender: 'string',
      identificationNo: 'string',
      monthlyIncome: 'string',
      selfEmployedMonthlyIncome: 'string',
      workingStatus: 'string',
      maritalStatus: 'string',
      highestQualification: 'string',
      occupationalTitle: 'string',
      isEmployed: 'bool',
      selfEmployed: 'bool',
      isRecentlyEmployed: 'bool',
      reasonOfUnemployment: 'string',
      referredToWsg: 'bool',
      isApplicantSpouse: 'bool',
      commencementDate: 'datetime',
      permanentResidenceStartDate: 'datetime',
      hasNoa: 'bool',
      hasCpf: 'bool',
    },
    GuardianAddress: {
      address: 'string',
      building: 'string',
      floor: 'string',
      unit: 'string',
      postcode: 'string',
    },
    GuardianRelationshipToChild: {
      fkRelation: 'number',
      fkChild: 'number',
    },
  },
  EmergencyContact: {
    firstName: 'string',
    lastName: 'string',
    identificationNo: 'string',
    mobilePhone: 'string',
    mobilePhoneCC: 'string',
    postalCode: 'string',
    address: 'string',
    floorNo: 'number',
    unitNo: 'number',
    relationship: 'number',
  },
  DefaultEmergencyContact: {
    firstName: 'string',
    lastName: 'string',
    identificationNo: 'string',
    mobilePhone: 'string',
    mobilePhoneCC: 'string',
    postalCode: 'string',
    address: 'string',
    floorNo: 'number',
    unitNo: 'number',
    relationship: 'number',
  },
});

const dispatchGetAdditionalInfo = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ADDITIONAL_INFO,
    key: 'additionalInfo',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchEnquiryById = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ENQUIRY_BY_ID,
    key: 'enquiryDetailById',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchEnquiryByIdECDAMVP = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ENQUIRY_BY_ID_ECDAMVP,
    key: 'ecdaRegistrationByID',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchGetEnrolmentForm = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ENROLMENT_FORM,
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const startRegistration = registrationID => async () => {
  try {
    const result = await SkApolloRequest({
      params: {
        mutation: START_REGISTRATION,
        variables: {
          IDRegistration: registrationID,
        },
      },
      type: 'mutation',
    });
    return result;
  } catch (ex) {
    return ex;
  }
};

export const startECDARegistration = async registrationID => {
  try {
    const result = await SkApolloRequest({
      params: {
        mutation: START_ECDA_REGISTRATION,
        variables: {
          IDRegistration: registrationID,
        },
      },
      type: 'mutation',
    });
    return result;
  } catch (ex) {
    return ex;
  }
};

export const fetchRegistrationDataById = registrationID => async () => {
  try {
    const result = await SkApolloRequest({
      params: {
        query: GET_REGISTRATION_BY_ID,
        variables: {
          IDRegistration: registrationID,
        },
      },
      type: 'query',
    });
    if (result.success) {
      const registration = get(result, 'data.registrationByID');
      return registration;
    }

    if (result.error) {
      return result.error;
    }

    return result;
  } catch (ex) {
    return ex;
  }
};

export const fetchRegistrationStatusById = registrationID => async () => {
  try {
    const result = await SkApolloRequest({
      params: {
        query: GET_REGISTRATION_STATUS_BY_ID,
        variables: {
          IDRegistration: registrationID,
        },
      },
      type: 'query',
    });
    return result.data;
  } catch (ex) {
    return ex;
  }
};

export const fetchEnquiryDataByIdECDAMVP = registrationID => async dispatch => {
  dispatchEnquiryByIdECDAMVP(dispatch);
  try {
    const result = await SkApolloRequest({
      params: {
        query: GET_REGISTRATION_BY_ID_ECDAMVP,
        variables: {
          IDRegistration: registrationID,
          filter: null,
        },
      },
      type: 'query',
    });
    if (result.success) {
      const registration = get(result, 'data.ecdaRegistrationByID');
      dispatchEnquiryByIdECDAMVP(dispatch, false, {
        data: registration,
        key: 'ecdaRegistrationByID',
      });
    } else {
      dispatchEnquiryByIdECDAMVP(dispatch, false, null, result.error);
    }

    return result;
  } catch (ex) {
    dispatchEnquiryByIdECDAMVP(dispatch, false, null, { error: ex.message });
    return ex;
  }
};

// Fetches Reg By ID, TODO: rename after old reg form is completely discarded
export const fetchEnquiryDataById = registrationID => async dispatch => {
  dispatchEnquiryById(dispatch);
  try {
    const result = await SkApolloRequest({
      params: {
        query: GET_REGISTRATION_BY_ID,
        variables: {
          IDRegistration: registrationID,
        },
      },
      type: 'query',
    });
    if (result.success) {
      const registration = get(result, 'data.registrationByID');
      dispatchEnquiryById(dispatch, false, {
        data: registration,
        key: 'enquiryDetailById',
      });
    } else {
      dispatchEnquiryById(dispatch, false, null, result.error);
    }

    return result;
  } catch (ex) {
    dispatchEnquiryById(dispatch, false, null, { error: ex.message });
    return ex;
  }
};

export const hasDuplicateSpouseEmailOrNric =
  (IDRegChild, identificationNo, email) => async () => {
    try {
      const result = await SkApolloRequest({
        params: {
          query: GET_CONFIRMATION_FOR_SPOUSE,
          variables: {
            IDRegChild,
            identificationNo,
            email,
          },
        },
        type: 'query',
      });
      return result;
    } catch (ex) {
      return ex;
    }
  };

export const saveEnrolmentForm = (enquiryID, formData) => async () => {
  const reqData = {
    IDEnquiry: enquiryID,
    EnrolmentForm: formData,
  };
  const query = mMutationBuilder.getMutationAsString(
    'saveEnrolmentForm',
    reqData
  );

  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });

    return data;
  } catch (ex) {
    return ex;
  }
};

export const getEnrolmentForm = IDEnquiry => async dispatch => {
  dispatchGetEnrolmentForm(dispatch);
  try {
    const reqData = {
      IDEnquiry,
    };
    const query = mQueryBuilder.getQueryAsString('getEnrolmentForm', reqData);
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchGetEnrolmentForm(dispatch, false, { data: data.data });
    } else {
      dispatchGetEnrolmentForm(dispatch, false, null, data.error);
    }

    return data;
  } catch (ex) {
    dispatchGetEnrolmentForm(dispatch, false, null, ex.message);
    return ex;
  }
};

export const fetchAdditionalInformation = IDEnquiry => async dispatch => {
  dispatchGetAdditionalInfo(dispatch);

  try {
    const query = mQueryBuilder.getQueryAsString('getAdditionalInformation', {
      IDEnquiry,
    });

    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchGetAdditionalInfo(dispatch, false, data.data);
    } else {
      dispatchGetAdditionalInfo(dispatch, false, null, data.error);
    }

    return data;
  } catch (ex) {
    dispatchGetAdditionalInfo(dispatch, false, null, ex.message);
    return ex;
  }
};

export const saveAdditionalInformation =
  (IDEnquiry, step, data) => async () => {
    const reqData = {
      IDEnquiry,
      step,
      data,
    };

    const query = mMutationBuilder.getMutationAsString(
      'saveAdditionalInformation',
      reqData
    );

    try {
      const retData = await SkRequest({
        data: {
          query,
        },
        method: 'POST',
      });

      return retData;
    } catch (ex) {
      return ex;
    }
  };

export default saveEnrolmentForm;

const dispatchRegEnrolmentForm = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: REG_ENROLMENT_FORM,
    key: 'regEnrolmentForm',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchEcdaRegEnrolmentForm = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: ECDA_REG_ENROLMENT_FORM,
    key: 'ecdaRegEnrolmentForm',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const getRegEnrolmentForm = registrationID => async dispatch => {
  dispatchRegEnrolmentForm(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_REG_ENROLMENT_FORM,
        variables: {
          registrationID,
        },
      },
      type: 'query',
    });
    if (data.success) {
      const resData = get(data, 'data.getRegEnrolmentForm');
      dispatchRegEnrolmentForm(dispatch, false, resData);
    } else {
      dispatchRegEnrolmentForm(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchRegEnrolmentForm(dispatch, false, null, {
      error: ex.message,
    });
  }
};

export const getEcdaRegEnrolmentForm = registrationID => async dispatch => {
  dispatchEcdaRegEnrolmentForm(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_ECDA_REG_ENROLMENT_FORM,
        variables: {
          registrationID,
        },
      },
      type: 'query',
    });
    if (data.success) {
      const resData = get(data, 'data.getECDARegEnrolmentForm');
      dispatchEcdaRegEnrolmentForm(dispatch, false, resData);
    } else {
      dispatchEcdaRegEnrolmentForm(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchEcdaRegEnrolmentForm(dispatch, false, null, {
      error: ex.message,
    });
  }
};

export const saveRegEnrolmentForm =
  (registrationID, step, regForm) => async dispatch => {
    try {
      const data = await SkApolloRequest({
        params: {
          query: SAVE_REG_ENROLMENT_FORM_GQL,
          variables: {
            registrationID,
            step,
            regForm,
          },
        },
        type: 'query',
      });
      if (data.success) {
        const resData = get(data, 'data.saveRegEnrolmentForm');
        dispatchRegEnrolmentForm(dispatch, false, resData);
      } else {
        dispatchRegEnrolmentForm(dispatch, false, null, data.error);
      }
      return data;
    } catch (ex) {
      dispatchRegEnrolmentForm(dispatch, false, null, {
        error: ex.message,
      });
    }
  };

export const saveEcdaRegEnrolmentForm =
  (registrationID, step, regForm) => async dispatch => {
    try {
      const data = await SkApolloRequest({
        params: {
          query: SAVE_ECDA_REG_ENROLMENT_FORM_GQL,
          variables: {
            registrationID,
            step,
            regForm,
          },
        },
        type: 'query',
      });
      if (data.success) {
        const resData = get(data, 'data.saveECDARegEnrolmentForm');
        dispatchEcdaRegEnrolmentForm(dispatch, false, resData);
      } else {
        dispatchEcdaRegEnrolmentForm(dispatch, false, null, data.error);
      }
      return data;
    } catch (ex) {
      dispatchEcdaRegEnrolmentForm(dispatch, false, null, {
        error: ex.message,
      });
    }
  };

export const requireConfirmationForECDASpouse = async (
  IDECDARegChild,
  identificationNo,
  email
) => {
  try {
    const result = await SkApolloRequest({
      params: {
        query: REQUIRE_CONFIRMATION_FOR_ECDA_SPOUSE,
        variables: {
          IDECDARegChild,
          identificationNo,
          email,
        },
      },
      type: 'query',
    });
    return result;
  } catch (ex) {
    return ex;
  }
};

export const refeshEcdaRegistration = registrationID => async dispatch => {
  try {
    const result = await SkApolloRequest({
      params: {
        query: GET_REGISTRATION_BY_ID_ECDAMVP,
        variables: {
          IDRegistration: registrationID,
          filter: null,
        },
      },
      type: 'query',
    });
    if (result.success) {
      const registration = get(result, 'data.ecdaRegistrationByID');
      dispatchEnquiryByIdECDAMVP(dispatch, false, {
        data: registration,
        key: 'ecdaRegistrationByID',
      });
    }
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    return { error: errorMessage };
  }
};
