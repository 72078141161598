import { get } from 'lodash';
import { MutationBuilder, QueryBuilder } from '../../queryBuilder';
import { DEFAULT_ERROR } from '../../../utils/constants';
import SkRequest from '../BaseRequest';
import { parseGraphQLError } from '../../../utils';
import {
  FETCH_AVAILABLE_BANKS,
  FETCH_PAYMENT_SESSION,
  FETCH_USER_ACCOUNT_LISTING,
  FETCH_ENQUIRY_PAYMENT_DETAILS_BY_ID,
  FETCH_ENQUIRY_MONTHLY_PAYMENT_DETAILS_BY_ID,
  FETCH_ACCOUNT_INFO_BY_ENQUIRY,
  FETCH_ACCOUNT_INFO_BY_ENQUIRY_GIRO,
  CLEAR_PAYMENT_SESSION,
  SET_GIRO_SETUP_STEP,
  CLEAR_GIRO_SETUP_STEP,
  FETCH_ACCOUNT_INFO_BY_REG,
  FETCH_ACCOUNT_INFO_BY_CHILD,
  FETCH_EGIRO_ACCOUNT_INFO_BY_REG,
  FETCH_EGIRO_ACCOUNT_INFO_BY_CHILD,
  FETCH_EGIRO_REDIRECTION,
  FETCH_ECDA_EGIRO_REDIRECTION,
  FETCH_ACCOUNT_INFO_BY_ECDA,
  FETCH_ECDA_ENQUIRY_MONTHLY_PAYMENT_DETAILS_BY_ID,
  FETCH_ECDA_ENQUIRY_PAYMENT_DETAILS_BY_ID,
  FETCH_EGIRO_ACCOUNT_INFO_BY_ECDA_REG,
} from '../actionTypes';
import apolloUtils, { SkApolloRequest } from '../../../utils/apolloUtils';
import {
  FIND_ACCOUNT_INFO_BY_ENQUIRY_ID,
  FIND_ACCOUNT_INFO_BY_CHILD_ID,
  GET_ACCOUNT_LISTING,
  ADD_GIRO,
  EXPORT_GIRO_PDF,
  EMAIL_GIRO_PDF,
  GET_PAYNOW_UEN_BY_SCHOOL,
  PAY_REGISTRATION_INVOICE_BY_GIRO,
  GET_PAYMENT_ACCESS,
  INITIATE_REFUND,
  MARK_GIRO_AS_COMPLETED,
  EGIRO_DETAILS_BY_REG_CHILD,
  EGIRO_DETAILS_BY_CHILD,
  EDDA_REDIRECTION,
  ECDA_EDDA_REDIRECTION,
  UPDATE_TRANSACTION_STATUS,
  ADD_BANK_ACCOUNT_FOR_REFUND,
  FIND_ECDA_ACCOUNT_INFO_BY_ID,
  GET_ECDA_REGISTRATION_INVOICE,
  EGIRO_DETAILS_BY_ECDA_REG_CHILD,
  INITIATE_REFUND_ECDA_REGISTRATION,
} from '../../models/Registration/paymentModels';

const _mMutationBuilder = new MutationBuilder();
const _mQueryBuilder = new QueryBuilder();

const mPaymentSessionDetails = {
  Id: 'string',
  Version: 'string',
  UpdateStatus: 'string',
  SuccessIndicator: 'string',
  MerchantId: 'string',
  Order: {
    Id: 'string',
    Amount: 'number',
    Currency: 'string',
    Description: 'string',
  },
};

_mMutationBuilder.setNewMutation(
  'createPaymentSession',
  mPaymentSessionDetails
);

_mQueryBuilder.setNewQuery('getPaymentSession', mPaymentSessionDetails);

_mMutationBuilder.setNewMutation('updatePaymentSession', {});

_mMutationBuilder.setNewMutation('payRegistrationFeeByGiro', {
  Success: 'boolean',
  TransactionNo: 'string',
  ErrorMessage: 'string',
});
_mMutationBuilder.setNewMutation('markRegistrationPaymentAsSuccessful', {});

_mMutationBuilder.setNewMutation('payGiroByInvoiceID', {});

_mQueryBuilder.setNewQuery('findAllBankBicCode', {
  data: {
    ID: 'number',
    bankCode: 'string',
    bankName: 'string',
    bicCode: 'string',
    displayOrder: 'number',
    isCdaSupported: 'boolean',
  },
});

_mQueryBuilder.setNewQuery('getRegistrationInvoice', {
  ID: 'number',
  totalAmount: 'number',
  outstandingAmount: 'number',
  invoiceNo: 'string',
  invoiceDate: 'datetime',
  invoiceDueDate: 'datetime',
  status: 'string',
  invoiceType: 'string',
  invoiceItems: {
    data: {
      ID: 'number',
      label: 'string',
      totalAmount: 'number',
      displayTotalAmount: 'string',
      displayAdjustedAmount: 'string',
      billableItem: {
        sn1StrAccPacAccountCode: 'string',
      },
    },
  },
  grandTotal: 'number',
  updatedAt: 'datetime',
  receipts: {
    data: {
      receiptNo: 'string',
      paymentType: 'string',
      amount: 'number',
      amountReceived: 'number',
      createdAt: 'datetime',
      updatedAt: 'datetime',
      documentNo: 'string',
      userByCollectedByFkStaff: {
        firstname: 'string',
        lastname: 'string',
      },
    },
  },
  sn1UpdatedBy: 'string',
  sn1VerifiedBy: 'string',
});

_mQueryBuilder.setNewQuery('getRegistrationDraftMonthlyFeeInvoice', {
  totalAmount: 'number',
  outstandingAmount: 'number',
  status: 'string',
  grandTotal: 'number',
  invoiceItems: {
    data: {
      label: 'string',
      totalAmount: 'number',
      displayTotalAmount: 'string',
      displayAdjustedAmount: 'string',
      billableItem: {
        type: 'string',
      },
    },
  },
});

_mQueryBuilder.setNewQuery('getEcdaRegistrationDraftMonthlyFeeInvoice', {
  totalAmount: 'number',
  outstandingAmount: 'number',
  status: 'string',
  grandTotal: 'number',
  invoiceItems: {
    data: {
      label: 'string',
      totalAmount: 'number',
      displayTotalAmount: 'string',
      displayAdjustedAmount: 'string',
      billableItem: {
        type: 'string',
      },
    },
  },
});

_mQueryBuilder.setNewQuery('bankAccountsByFkChild', {
  data: {
    ID: 'number',
    isCda: 'boolean',
    billReferenceNumber: 'string',
    payerAccountName: 'string',
    payerAccountNumber: 'string',
    payerBankBranch: 'string',
    status: 'string',
    active: 'bool',
    source: 'string',
    fkBankBicCode: 'string',
  },
});

_mQueryBuilder.setNewQuery('getTrialPeriodInvoice', {
  ID: 'number',
  invoiceNo: 'string',
  status: 'string',
  outstandingAmount: 'number',
  totalAmount: 'number',
});

_mQueryBuilder.setNewQuery('getPaymentAccess', {});

const dispatchFetchPaymentSession = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_PAYMENT_SESSION,
    key: 'paymentSession',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const dispatchClearPaymentSession =
  (data = {}) =>
  dispatch => {
    dispatch({
      type: CLEAR_PAYMENT_SESSION,
      key: 'paymentSession',
      value: data,
    });
  };

const dispatchAccountListing = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_USER_ACCOUNT_LISTING,
    key: 'accountListing',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchAccountByEnquiry = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ACCOUNT_INFO_BY_ENQUIRY,
    key: 'accountInfoByEnquiry',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchAccountByReg = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ACCOUNT_INFO_BY_REG,
    key: 'accountInfoByReg',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchAccountByECDA = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ACCOUNT_INFO_BY_ECDA,
    key: 'accountInfoByECDA',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchEgiroDetailsByRegChild = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_EGIRO_ACCOUNT_INFO_BY_REG,
    key: 'egiroAccountByRegChild',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchEgiroDetailsByECDARegChild = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_EGIRO_ACCOUNT_INFO_BY_ECDA_REG,
    key: 'egiroAccountByECDARegChild',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchEgiroDetailsByFkChild = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_EGIRO_ACCOUNT_INFO_BY_CHILD,
    key: 'egiroAccountByChild',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchEddaRedirection = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_EGIRO_REDIRECTION,
    key: 'egiroRedirection',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchECDAEddaRedirection = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ECDA_EGIRO_REDIRECTION,
    key: 'ecdaEgiroRedirection',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchAccountByEnquiryForGiro = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ACCOUNT_INFO_BY_ENQUIRY_GIRO,
    key: 'accountInfoByEnquiryForGiro',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchAccountByChild = (
  dispatch,
  inProgress = true,
  data = [],
  error = null
) => {
  dispatch({
    type: FETCH_ACCOUNT_INFO_BY_CHILD,
    key: 'accountInfoByChild',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchAvailableBanks = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_AVAILABLE_BANKS,
    key: 'availableBanks',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchEnquiryMonthlyPaymentDetailsById = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ENQUIRY_MONTHLY_PAYMENT_DETAILS_BY_ID,
    key: 'enquiryMonthlyPaymentDetailById',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchECDAMonthlyPaymentDetailsById = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ECDA_ENQUIRY_MONTHLY_PAYMENT_DETAILS_BY_ID,
    key: 'ecdaMonthlyPaymentDetailById',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchRegistrationPaymentById = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ENQUIRY_PAYMENT_DETAILS_BY_ID,
    key: 'registrationPaymentById',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchECDARegistrationPaymentById = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ECDA_ENQUIRY_PAYMENT_DETAILS_BY_ID,
    key: 'ecdaRegistrationPaymentById',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const getTrialInvoice = IDEnquiry => async () => {
  try {
    const data = await SkRequest({
      data: {
        query: _mQueryBuilder.getQueryAsString('getTrialPeriodInvoice', {
          IDEnquiry,
        }),
      },
      method: 'POST',
    });

    return data;
  } catch (ex) {
    return ex;
  }
};

export const fetchAvailableBanks = () => async dispatch => {
  dispatchFetchAvailableBanks(dispatch);

  try {
    const data = await SkRequest({
      data: {
        query: _mQueryBuilder.getQueryAsString('findAllBankBicCode', {
          pagination: {
            sort: ['display_order', 'bankName'],
          },
        }),
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchFetchAvailableBanks(dispatch, false, { data: data.data });
    } else {
      dispatchFetchAvailableBanks(dispatch, false, null, data.error);
    }

    return data;
  } catch (ex) {
    dispatchFetchAvailableBanks(dispatch, false, null, {
      error: ex.message,
    });
    return ex;
  }
};

export const fetchUserAccounts =
  ({ isCDA, paymentType }) =>
  async dispatch => {
    dispatchAccountListing(dispatch);
    const client = await apolloUtils();

    try {
      const result = await client.query({
        query: GET_ACCOUNT_LISTING,
        variables: {
          isCDA,
          paymentType,
        },
      });

      if (
        !result.loading &&
        get(result, 'data.getRegistrationAccountListing')
      ) {
        dispatchAccountListing(dispatch, false, { data: result.data });
      } else {
        dispatchAccountListing(dispatch, false, null, result.errors);
      }

      return result;
    } catch (ex) {
      dispatchAccountListing(dispatch, false, null, { error: ex.message });
      return ex;
    }
  };

export const getMonthlyFeeForEnquiry = IDRegistration => async dispatch => {
  dispatchEnquiryMonthlyPaymentDetailsById(dispatch);
  try {
    const data = await SkRequest({
      data: {
        query: _mQueryBuilder.getQueryAsString(
          'getRegistrationDraftMonthlyFeeInvoice',
          {
            IDRegistration,
          }
        ),
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchEnquiryMonthlyPaymentDetailsById(dispatch, false, {
        data: data.data,
      });
    } else {
      dispatchEnquiryMonthlyPaymentDetailsById(
        dispatch,
        false,
        null,
        data.error
      );
    }

    return data;
  } catch (ex) {
    dispatchEnquiryMonthlyPaymentDetailsById(dispatch, false, null, {
      error: ex.message,
    });
    return ex;
  }
};

export const getPaymentDetailsForEnquiry = registrationID => async dispatch => {
  dispatchRegistrationPaymentById(dispatch);
  try {
    const data = await SkRequest({
      data: {
        query: _mQueryBuilder.getQueryAsString('getRegistrationInvoice', {
          IDRegistration: registrationID,
        }),
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchRegistrationPaymentById(dispatch, false, {
        data: data.data,
        key: 'registrationPaymentById',
      });
    } else {
      dispatchRegistrationPaymentById(dispatch, false, null, data.error);
    }

    return data;
  } catch (ex) {
    dispatchRegistrationPaymentById(dispatch, false, null, {
      error: ex.message,
    });
    return ex;
  }
};

export const getECDAPaymentDetailsForEnquiry =
  registrationID => async dispatch => {
    dispatchFetchECDARegistrationPaymentById(dispatch);
    try {
      const result = await SkApolloRequest({
        params: {
          query: GET_ECDA_REGISTRATION_INVOICE,
          variables: {
            IDRegistration: registrationID,
          },
        },
        type: 'query',
      });

      if (result.success) {
        dispatchFetchECDARegistrationPaymentById(dispatch, false, {
          data: result.data,
          key: 'ecdaRegistrationPaymentById',
        });
      } else {
        dispatchFetchECDARegistrationPaymentById(
          dispatch,
          false,
          null,
          result.error
        );
      }
    } catch (ex) {
      dispatchFetchECDARegistrationPaymentById(dispatch, false, null, {
        error: parseGraphQLError(ex),
      });
    }
  };

export const getPaymentSession = paymentOrderID => async dispatch => {
  dispatchFetchPaymentSession(dispatch);

  try {
    const data = await SkRequest({
      data: {
        query: _mQueryBuilder.getQueryAsString('getPaymentSession', {
          paymentOrderID,
        }),
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchFetchPaymentSession(dispatch, false, { data: data.data });
    } else {
      dispatchFetchPaymentSession(dispatch, false, null, data.error);
    }

    return data;
  } catch (ex) {
    dispatchFetchPaymentSession(dispatch, false, null, {
      error: ex.message,
    });
    return ex;
  }
};

export const updatePaymentSession = paymentOrderID => async () => {
  try {
    return await SkRequest({
      data: {
        query: _mMutationBuilder.getMutationAsString('updatePaymentSession', {
          paymentOrderID,
        }),
      },
      method: 'POST',
    });
  } catch (ex) {
    return ex;
  }
};

export const createPaymentSession = InvoiceID => async () => {
  try {
    return await SkRequest({
      data: {
        query: _mMutationBuilder.getMutationAsString('createPaymentSession', {
          InvoiceID,
        }),
      },
      method: 'POST',
    });
  } catch (ex) {
    return ex;
  }
};

export const payUserGiro = reqData => async () => {
  try {
    return await SkRequest({
      data: {
        query: _mMutationBuilder.getMutationAsString(
          'payGiroByInvoiceID',
          reqData
        ),
      },
      method: 'POST',
    });
  } catch (ex) {
    throw ex;
  }
};

export const completeGiroSetup = IDRegistration => async () => {
  try {
    return await SkApolloRequest({
      params: {
        mutation: MARK_GIRO_AS_COMPLETED,
        variables: { IDRegistration },
      },
      type: 'mutation',
    });
  } catch (ex) {
    throw ex;
  }
};

export const addUserGiro =
  ({ giroAccount, paymentType }) =>
  async () => {
    const client = await apolloUtils();
    try {
      return await client.mutate({
        mutation: ADD_GIRO,
        variables: {
          giroAccount,
          paymentType,
        },
      });
    } catch (ex) {
      throw ex;
    }
  };

export const getPayNowUENBySchool = schoolID => async () => {
  const client = await apolloUtils();
  try {
    return await client.query({
      query: GET_PAYNOW_UEN_BY_SCHOOL,
      variables: { schoolID },
    });
  } catch (ex) {
    throw ex;
  }
};

export const fetchAccountInfoByEnquiry = regChildId => async dispatch => {
  dispatchFetchAccountByEnquiry(dispatch);
  try {
    const result = await SkApolloRequest({
      params: {
        query: FIND_ACCOUNT_INFO_BY_ENQUIRY_ID,
        variables: { regChildId },
      },
      type: 'query',
    });
    if (result.success) {
      dispatchFetchAccountByEnquiry(dispatch, false, result.data);
    } else {
      dispatchFetchAccountByEnquiry(dispatch, false, null, result.error);
    }
    return result;
  } catch (ex) {
    dispatchFetchAccountByEnquiry(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

export const fetchAccountInfoByReg = regChildId => async dispatch => {
  dispatchFetchAccountByReg(dispatch);
  try {
    const result = await SkApolloRequest({
      params: {
        query: FIND_ACCOUNT_INFO_BY_ENQUIRY_ID,
        variables: { regChildId },
      },
      type: 'query',
    });
    if (result.success) {
      dispatchFetchAccountByReg(dispatch, false, result.data);
    } else {
      dispatchFetchAccountByReg(dispatch, false, null, result.error);
    }
    return result;
  } catch (ex) {
    dispatchFetchAccountByReg(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

export const fetchAccountInfoByECDA = IDEcdaRegChild => async dispatch => {
  dispatchFetchAccountByECDA(dispatch);
  try {
    const result = await SkApolloRequest({
      params: {
        query: FIND_ECDA_ACCOUNT_INFO_BY_ID,
        variables: { IDEcdaRegChild },
      },
      type: 'query',
    });
    if (result.success) {
      dispatchFetchAccountByECDA(dispatch, false, result.data);
    } else {
      dispatchFetchAccountByECDA(dispatch, false, null, result.error);
    }
    return result;
  } catch (ex) {
    dispatchFetchAccountByECDA(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

export const fetchAccountInfoByChild = regChildId => async dispatch => {
  dispatchFetchAccountByChild(dispatch);
  const client = await apolloUtils();

  try {
    const result = await client.query({
      query: FIND_ACCOUNT_INFO_BY_CHILD_ID,
      variables: {
        regChildId,
      },
    });
    const data = get(result, 'data.getBankAccountByFkRegistrationChild', []);
    dispatchFetchAccountByChild(dispatch, false, data);

    return result.data;
  } catch (ex) {
    dispatchFetchAccountByChild(dispatch, false, null, {
      error: ex.message,
    });
    throw ex;
  }
};

export const payRegistrationInvoiceByGiro =
  ({ IDRegChild, IDInvoice }) =>
  async () => {
    const client = await apolloUtils();
    try {
      return await client.query({
        query: PAY_REGISTRATION_INVOICE_BY_GIRO,
        variables: { IDRegChild, IDInvoice },
      });
    } catch (ex) {
      throw ex;
    }
  };

export const fetchAccountInfoByEnquiryForGiro = IDEnquiry => async dispatch => {
  dispatchFetchAccountByEnquiryForGiro(dispatch);
  const client = await apolloUtils();

  try {
    const result = await client.query({
      query: FIND_ACCOUNT_INFO_BY_ENQUIRY_ID,
      variables: {
        IDEnquiry,
      },
    });

    if (!result.loading && get(result, 'data.bankAccountInfoByIDEnquiry')) {
      dispatchFetchAccountByEnquiryForGiro(dispatch, false, {
        data: result.data,
      });
    } else {
      dispatchFetchAccountByEnquiryForGiro(
        dispatch,
        false,
        null,
        get(result, 'data.error', DEFAULT_ERROR)
      );
    }

    return result.data;
  } catch (ex) {
    dispatchFetchAccountByEnquiryForGiro(dispatch, false, null, {
      error: ex.message,
    });
    throw ex;
  }
};

export const getPaymentAccess = reqData => async dispatch => {
  dispatchFetchPaymentSession(dispatch);
  const client = await apolloUtils();

  try {
    const data = await client.query({
      query: GET_PAYMENT_ACCESS,
      variables: reqData,
    });

    if (!data.loading && get(data, 'data.getRegistrationPaymentAccess')) {
      dispatchFetchPaymentSession(dispatch, false, { data: data.data });
    } else {
      dispatchFetchPaymentSession(dispatch, false, null, data.error);
    }

    return data;
  } catch (ex) {
    dispatchFetchPaymentSession(dispatch, false, null, {
      error: ex.message,
    });
    return ex;
  }
};

export const dispatchSetGiroSetupStep = data => dispatch => {
  dispatch({
    type: SET_GIRO_SETUP_STEP,
    key: 'giroSetupStepDetail',
    value: {
      inProgress: false,
      data,
      error: null,
    },
  });
};

export const dispatchClearGiroSetupStep =
  (data = {}) =>
  dispatch => {
    dispatch({
      type: CLEAR_GIRO_SETUP_STEP,
      key: 'giroSetupStepDetail',
      value: {
        inProgress: false,
        data,
        error: null,
      },
    });
  };

export const exportGiroPdf = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: EXPORT_GIRO_PDF,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const emailGiroPdf = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: EMAIL_GIRO_PDF,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const initiateRefundForCancelledRegistration = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: INITIATE_REFUND,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const initiateRefundForCancelledEcdaRegistration =
  reqData => async () => {
    try {
      const data = await SkApolloRequest({
        params: {
          mutation: INITIATE_REFUND_ECDA_REGISTRATION,
          variables: reqData,
        },
        type: 'mutation',
      });
      return data;
    } catch (ex) {
      return ex;
    }
  };

export const fetchEgiroDetailsByRegChild =
  registrationChildId => async dispatch => {
    dispatchFetchEgiroDetailsByRegChild(dispatch);
    try {
      const result = await SkApolloRequest({
        params: {
          query: EGIRO_DETAILS_BY_REG_CHILD,
          variables: { registrationChildId },
        },
        type: 'query',
      });
      if (result.success) {
        dispatchFetchEgiroDetailsByRegChild(dispatch, false, result.data);
      } else {
        dispatchFetchEgiroDetailsByRegChild(
          dispatch,
          false,
          null,
          result.error
        );
      }
      return result;
    } catch (ex) {
      dispatchFetchEgiroDetailsByRegChild(dispatch, false, null, {
        error: parseGraphQLError(ex),
      });
    }
  };

export const fetchEgiroDetailsByECDARegChild =
  ecdaRegistrationChildId => async dispatch => {
    dispatchFetchEgiroDetailsByECDARegChild(dispatch);
    try {
      const result = await SkApolloRequest({
        params: {
          query: EGIRO_DETAILS_BY_ECDA_REG_CHILD,
          variables: { ecdaRegistrationChildId },
        },
        type: 'query',
      });
      if (result.success) {
        dispatchFetchEgiroDetailsByECDARegChild(dispatch, false, result.data);
      } else {
        dispatchFetchEgiroDetailsByECDARegChild(
          dispatch,
          false,
          null,
          result.error
        );
      }
      return result;
    } catch (ex) {
      dispatchFetchEgiroDetailsByECDARegChild(dispatch, false, null, {
        error: parseGraphQLError(ex),
      });
    }
  };

export const fetchEgiroDetailsByFkChild = childId => async dispatch => {
  dispatchFetchEgiroDetailsByFkChild(dispatch);
  try {
    const result = await SkApolloRequest({
      params: {
        query: EGIRO_DETAILS_BY_CHILD,
        variables: { childId },
      },
      type: 'query',
    });
    if (result.success) {
      dispatchFetchEgiroDetailsByFkChild(dispatch, false, result.data);
    } else {
      dispatchFetchEgiroDetailsByFkChild(dispatch, false, null, result.error);
    }
    return result;
  } catch (ex) {
    dispatchFetchEgiroDetailsByFkChild(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

export const fetchEddaRedirection = reqData => async dispatch => {
  dispatchFetchEddaRedirection(dispatch);
  try {
    const result = await SkApolloRequest({
      params: {
        query: EDDA_REDIRECTION,
        variables: reqData,
      },
      type: 'query',
    });
    if (result.success) {
      dispatchFetchEddaRedirection(dispatch, false, result.data);
    } else {
      dispatchFetchEddaRedirection(dispatch, false, null, result.error);
    }
    return result;
  } catch (ex) {
    dispatchFetchEddaRedirection(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

export const fetchECDAEddaRedirection = reqData => async dispatch => {
  dispatchFetchECDAEddaRedirection(dispatch);
  try {
    const result = await SkApolloRequest({
      params: {
        query: ECDA_EDDA_REDIRECTION,
        variables: reqData,
      },
      type: 'query',
    });
    if (result.success) {
      dispatchFetchECDAEddaRedirection(dispatch, false, result.data);
    } else {
      dispatchFetchECDAEddaRedirection(dispatch, false, null, result.error);
    }
    return result;
  } catch (ex) {
    dispatchFetchECDAEddaRedirection(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

export const updateEgiroTransactionStatus = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: UPDATE_TRANSACTION_STATUS,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const addBankAccountRefundForCancelledReg = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: ADD_BANK_ACCOUNT_FOR_REFUND,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const getECDAMonthlyFeeForEnquiry = IDRegistration => async dispatch => {
  dispatchECDAMonthlyPaymentDetailsById(dispatch);
  try {
    const data = await SkRequest({
      data: {
        query: _mQueryBuilder.getQueryAsString(
          'getEcdaRegistrationDraftMonthlyFeeInvoice',
          {
            IDRegistration,
          }
        ),
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchECDAMonthlyPaymentDetailsById(dispatch, false, {
        data: data.data,
      });
    } else {
      dispatchECDAMonthlyPaymentDetailsById(dispatch, false, null, data.error);
    }

    return data;
  } catch (ex) {
    dispatchECDAMonthlyPaymentDetailsById(dispatch, false, null, {
      error: ex.message,
    });
    return ex;
  }
};

export default getPaymentAccess;
